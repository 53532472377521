import React from "react";

const GroupListItem = ({ name, logo, description }) => {
    return (
        <div style={styles.container}>
            <div style={styles.imageContainer}>
                <img src={logo} alt={name} style={styles.image} />
            </div>
            <div
                style={{
                    flexGrow: 1,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                }}
            >
                <h2 style={styles.title}>{name}</h2>
                <p style={styles.number}>{description}</p>
            </div>
        </div>
    );
};

const styles = {
    container: {
        display: "flex",
        gap: 8,
        border: "1px solid #ddd",
        borderRadius: "8px",
        padding: "4px",
        width: "100%",
        flex: 1,
        overflow: "hidden",
    },
    imageContainer: {
        flexBasis: 30,
        height: 30,
        width: 30,
        minWidth: 30,
        aspectRatio: "1/1",
        margin: "0 auto",
        borderRadius: "1000px",
        overflow: "hidden",
        display: "flex",
        justifyContent: "center",
        border: "1px solid #ddd",
    },
    image: {
        width: "100%",
        height: "auto",
    },
    title: {
        fontSize: "0.9rem",
        margin: "0",
        whiteSpace: "pre-line",
        wordBreak: "break-word",
    },
    number: {
        fontSize: "1.1rem",
        color: "#E5576C",
        fontWeight: 600,
        textAlign: "right",
        margin: "0",
    },
};

export default GroupListItem;
