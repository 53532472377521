import React from "react";
import classNames from "classnames";

export const FlexContainer = ({
    children,
    className,
    justifyContent,
    alignItems,
    column,
    gap,
    ...props
}) => {
    return (
        <div
            {...props}
            style={{
                gap: gap,
                flexDirection: column ? "column" : "row",
                ...props.style,
                display: "flex",
            }}
            className={classNames(
                {
                    [`justify-content-${justifyContent}`]: justifyContent,
                    [`align-items-${alignItems}`]: alignItems,
                    "flex-column": column,
                },
                className
            )}
        >
            {children}
        </div>
    );
};
