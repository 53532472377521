import { AddAPhoto, Delete } from "@mui/icons-material";
import Button from "components/CustomButtons/Button.js";
import React, { useRef, useState } from "react";
import styles from "./SingleImageUpload.module.scss";
import classNames from "classnames";
import SweetAlert from "react-bootstrap-sweetalert";
import { createUseStyles } from "react-jss";
import stylesAlert from "../../assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

const actionStyles = {
    actionButton: {
        padding: "5px 2px 5px 5px",
        marginLeft: "5px",
    },
    ...stylesAlert,
};

const useStyles = createUseStyles(actionStyles);

export default function SingleImageUpload({
    image,
    onUpload,
    onDelete,
    index,
    id,
    overlay,
    style,
}) {
    const [resizedImage, setResizedImage] = useState(null);
    const canvasRef = useRef(null);
    const classes = useStyles();
    const [isDragOver, setIsDragOver] = useState(false);
    const [alert, setAlert] = useState(null);

    const handleDragOver = (event) => {
        setIsDragOver(true);
    };

    const handleDragLeave = () => {
        setIsDragOver(false);
    };

    const handleDrop = (event) => {
        setIsDragOver(false);
    };

    const hideAlert = () => {
        setAlert(null);
    };

    const handleImageChange = (e) => {
        e.preventDefault();
        let reader = new FileReader();
        let newFile = e.target.files[0];
        var fileTypes = ["jpg", "jpeg", "png"]; //acceptable file types
        var extension = newFile && newFile.name.split(".").pop().toLowerCase(), //file extension from input file
            isSuccess = fileTypes.indexOf(extension) > -1; //is extension in acceptable types

        if (newFile && newFile.size >= 800000) {
            setAlert(
                <SweetAlert
                    info
                    style={{
                        display: "block",
                        marginTop: "-100px",
                    }}
                    title="Myndin er of stór"
                    onConfirm={() => hideAlert()}
                    onCancel={() => hideAlert()}
                    confirmBtnText="Í lagi"
                    confirmBtnCssClass={classes.button + " " + classes.success}
                >
                    Má aðeins vera 800 KB eða minni.
                </SweetAlert>
            );
            return;
        }

        if (!isSuccess) {
            setAlert(
                <SweetAlert
                    info
                    style={{
                        display: "block",
                        marginTop: "-100px",
                    }}
                    title="Ekki rétt skráartegund"
                    onConfirm={() => hideAlert()}
                    onCancel={() => hideAlert()}
                    confirmBtnText="Í lagi"
                    confirmBtnCssClass={classes.button + " " + classes.success}
                >
                    Skráin verður að vera af gerðinni jpg, jpeg eða png.
                </SweetAlert>
            );
            return;
        }

        reader.onloadend = () => {
            onUpload(reader.result, index, id);
        };
        if (newFile) {
            reader.readAsDataURL(newFile);
        }
    };

    if (image === null || image === undefined || image === "") {
        return (
            <>
                {alert}
                <div
                    style={style}
                    className={classNames(styles.emptyContainer, [
                        isDragOver ? styles.drag_over : "",
                    ])}
                    onDragOver={handleDragOver}
                    onDragLeave={handleDragLeave}
                    onDrop={handleDrop}
                >
                    {/* {overlay} */}
                    <AddAPhoto style={{ height: 30, width: 30, margin: 0, color: "#de2c48" }} />
                    <input type="file" onChange={handleImageChange} />
                </div>
            </>
        );
    }

    return (
        <>
            {alert}
            <canvas ref={canvasRef} style={{ display: "none" }}></canvas>
            <div
                style={style}
                className={classNames(styles.container, [isDragOver ? styles.drag_over : ""])}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
            >
                <img src={image} alt="" />
                {overlay}
                <input type="file" onChange={handleImageChange} />
                <Button
                    color="danger"
                    className={styles.deleteButton}
                    onClick={() => onDelete(index)}
                >
                    <Delete style={{ height: 30, width: 30, margin: 0 }} />
                </Button>
            </div>
        </>
    );
}
