import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

// core components
import Wizard from "../../../components/Wizard/Wizard.js";
import GridContainer from "../../../components/Grid/GridContainer.js";
import GridItem from "../../../components/Grid/GridItem.js";
import SnackbarCustom from "../../../components/Snackbar/Snackbar.js";
import { AddAlert } from "@mui/icons-material";

import Step1 from "./AddSteps/Step1.js";
import Step2 from "./AddSteps/Step2.js";

import { createUpdateCompany } from "../../../store/actions/company";
import { getRegions } from "../../../store/actions/region";
import { getCompanyCategories } from "../../../store/actions/companyCategory";
import { LoaderSpinner } from "../../../components/Loader";

import wizardStyle from "assets/jss/material-dashboard-pro-react/components/wizardStyle.js";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";
const style = {
    inputAdornment: {
        position: "relative",
    },
    colorPickerIcon: {
        position: "relative",
        cursor: "pointer",
        color: "#de2c49",
        "& svg": {
            color: "#de2c49",
        },
    },
    dSelected: {
        color: "#DE2C48",
        fontSize: "14px",
    },
    selectMenu: {
        color: "#DE2C48",
        fontSize: "14px",
    },
    select: {
        color: "#4c535a",
        fontSize: "14px",
    },
    txtArea: {
        height: "84px",
        width: "100%",
        borderColor: "rgb(210, 210, 210)",
        fontSize: "14px",
        fontFamily: "Roboto, Helvetica, Arial",
        fontWeight: 400,
        lineHeight: 1.42857,
        resize: "none",
    },
    paddingRow: {
        padding: "15px 15px !important",
    },
    label: {
        fontSize: "14px",
    },
    selectLabel: {
        fontSize: "14px",
    },
    social: {
        width: "40px",
        height: "40px",
        color: "#989797",
        margin: "0px 10px",
        cursor: "pointer",
    },
    socialA: {
        width: "40px",
        height: "40px",
        color: "#00acc1",
        margin: "0px 10px",
        cursor: "pointer",
    },
    socialTxt: {
        fontWeight: "normal",
        color: "#767676",
        fontSize: "14px",
    },
    socialD: {
        cursor: "pointer",
        color: "#cc1a06",
    },
    tagItem: {
        backgroundColor: "#d4d5d6",
        // display: 'inline-block',
        fontSize: "14px",
        borderRadius: "30px",
        height: "30px",
        padding: "0 4px 0 1rem",
        display: "inline-flex",
        alignItems: "center",
        margin: "0 0.3rem 0.3rem 0",
    },
    button: {
        backgroundColor: "white",
        width: "22px",
        height: "22px",
        borderRadius: "50%",
        border: "none",
        cursor: "pointer",
        font: "inherit",
        marginLeft: "10px",
        fontWeight: "bold",
        padding: "0",
        lineHeight: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    line: {
        borderColor: "#b6eaf1",
        height: "1px",
        marginBottom: 0,
    },
    rowMargin: {
        marginTop: "20px",
    },
    ...customSelectStyle,
    ...customCheckboxRadioSwitch,
    ...wizardStyle,
};
const useStyles = createUseStyles(style);

const AddCompany = ({ t }) => {
    const dispatch = useDispatch();
    const history = useNavigate();
    const { state } = useLocation();
    const classes = useStyles();
    const { status, message, type } = useSelector((state) => state.company.notification);
    const { regions, loading } = useSelector((state) => state.region);
    const isUpdating = !!(state && state.data);
    const userData = (state && state.data) || {};
    const { categories, loadingCategories } = useSelector((state) => state.companyCategory);

    const onSubmit = (companyData) => {
        if (!companyData.about.facebookState && companyData.about.facebook) {
            companyData.about.facebook = "";
            companyData.about.facebookState = true;
        }
        if (!companyData.about.twitterState && companyData.about.twitter) {
            companyData.about.twitter = "";
            companyData.about.twitterState = true;
        }
        if (!companyData.about.instagramState && companyData.about.instagram) {
            companyData.about.instagram = "";
            companyData.about.instagramState = true;
        }
        if (!companyData.about.youtubeState && companyData.about.youtube) {
            companyData.about.youtube = "";
            companyData.about.youtubeState = true;
        }
        if (!companyData.about.pinterestState && companyData.about.pinterest) {
            companyData.about.pinterest = "";
            companyData.about.pinterestState = true;
        }
        if (!companyData.about.linkedinState && companyData.about.linkedin) {
            companyData.about.linkedin = "";
            companyData.about.linkedinState = true;
        }

        if (companyData.about.tagsService) {
            companyData.about.tagsService.map((value) =>
                companyData.about.torgid_company_tags.push({ type: "service", value: value })
            );
        }
        if (companyData.about.tagsBrand) {
            companyData.about.tagsBrand.map((value) =>
                companyData.about.torgid_company_tags.push({ type: "brand", value: value })
            );
        }
        if (companyData.about.awards) {
            companyData.about.torgid_company_awards = companyData.about.awards.map((value) => {
                let newArr = value.split(":");
                return { type: newArr[0], value: newArr[1] || null };
            });
        }

        dispatch(createUpdateCompany({ update: isUpdating, ...companyData }));
    };

    useEffect(() => {
        dispatch(getCompanyCategories());
        dispatch(getRegions());
    }, [dispatch]);

    if (loading || loadingCategories) return <LoaderSpinner />;

    return (
        <GridContainer justifyContent="center">
            <SnackbarCustom
                place="br"
                icon={AddAlert}
                color={type === "success" ? "success" : "warning"}
                message={message}
                open={status}
            />
            <GridItem xs={12} sm={10}>
                <Wizard
                    validate
                    classes={classes}
                    history={history}
                    user={userData}
                    data={regions}
                    data2={categories ? categories.results : []}
                    steps={[
                        { stepName: t("company.about"), stepComponent: Step1, stepId: "about" },
                        {
                            stepName: t("company.locations"),
                            stepComponent: Step2,
                            stepId: "locations",
                        },
                    ]}
                    title={isUpdating ? t("company.update") : t("company.build")}
                    nextButtonText={t("next")}
                    cancelButtonText={t("cancel")}
                    previousButtonText={t("previous")}
                    finishButtonText={t("finish")}
                    finishButtonClick={onSubmit}
                />
            </GridItem>
        </GridContainer>
    );
};

export default withTranslation("", { withRef: true })(AddCompany);
