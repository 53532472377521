import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { createUseStyles } from "react-jss";
import { FormControl, InputLabel, FormHelperText, Input, OutlinedInput } from "@mui/material";

import styles from "../../assets/jss/material-dashboard-pro-react/components/customInputStyle.js";
const useStyles = createUseStyles(styles);

export default function CustomInput(props) {
    const classes = useStyles();
    const {
        formControlProps,
        formStyle,
        labelText,
        labelStyle,
        id,
        labelProps,
        inputProps,
        inputStyle,
        error,
        white,
        inputRootCustomClasses,
        success,
        helperText,
    } = props;

    const labelClasses = classNames({
        [" " + classes.labelRootError]: error,
        [" " + classes.labelRootSuccess]: success && !error,
    });
    const underlineClasses = classNames({
        [classes.underlineError]: error,
        [classes.underlineSuccess]: success && !error,
        [classes.underline]: true,
        [classes.whiteUnderline]: white,
    });
    const marginTop = classNames({
        [inputRootCustomClasses]: inputRootCustomClasses !== undefined,
    });
    const inputClasses = classNames({
        [classes.input]: true,
        [classes.whiteInput]: white,
    });
    var formControlClasses;
    if (formControlProps !== undefined) {
        formControlClasses = classNames(formControlProps.className, classes.formControl);
    } else {
        formControlClasses = classes.formControl;
    }
    var helpTextClasses = classNames({
        [classes.labelRootError]: error,
        [classes.labelRootSuccess]: success && !error,
    });
    let newInputProps = {
        maxLength: inputProps && inputProps.maxLength ? inputProps.maxLength : undefined,
        minLength: inputProps && inputProps.minLength ? inputProps.minLength : undefined,
    };
    return (
        <FormControl
            {...formControlProps}
            className={formControlClasses}
            style={{ ...formStyle, marginTop: 0, marginBottom: 0 }}
        >
            {labelText !== undefined ? (
                <InputLabel
                    className={classes.labelRoot + " " + labelClasses}
                    style={labelStyle}
                    shrink={true}
                    htmlFor={id}
                    {...labelProps}
                    sx={{ background: "white", padding: "0 4px" }}
                >
                    {labelText}
                </InputLabel>
            ) : null}
            <OutlinedInput
                id={id}
                classes={{
                    input: inputClasses,
                    root: marginTop,
                    disabled: classes.disabled,
                }}
                style={{ ...inputStyle, padding: 0, paddingRight: 14 }}
                {...inputProps}
                inputProps={{ ...newInputProps, style: { padding: "8px 16px" } }}
            />
            {helperText !== undefined ? (
                <FormHelperText id={id + "-text"} className={helpTextClasses}>
                    {helperText}
                </FormHelperText>
            ) : null}
        </FormControl>
    );
}

CustomInput.propTypes = {
    labelText: PropTypes.node,
    labelProps: PropTypes.object,
    labelStyle: PropTypes.object,
    id: PropTypes.string,
    inputProps: PropTypes.object,
    formControlProps: PropTypes.object,
    inputRootCustomClasses: PropTypes.string,
    inputStyle: PropTypes.object,
    formStyle: PropTypes.object,
    error: PropTypes.bool,
    success: PropTypes.bool,
    white: PropTypes.bool,
    helperText: PropTypes.node,
};
