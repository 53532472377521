import React, {useEffect} from 'react';
import { useDispatch, useSelector} from 'react-redux';
import {Link, useNavigate} from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import {withTranslation} from 'react-i18next';
import { Permissions } from '../../config'

// material-ui
import {Assignment, Person, Edit, AddAlert, Search} from "@mui/icons-material";
import {createUseStyles} from "react-jss";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import SnackbarCustom from "components/Snackbar/Snackbar.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import DeleteButton from "components/Main/DeleteButton.js";

import stylesAlert from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import styles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";

import {deleteUser, getUsers, searchUser} from "../../store/actions/user";
import {LoaderSpinner} from "../../components/Loader";

const style = {
  ...styles,
  ...stylesAlert
};

const useStyles = createUseStyles(style);

const AppUser = ({ t }) => {
  const classes = useStyles();
  const dispatch = useDispatch ();
  const navigate = useNavigate();
  const {appUsers, loading, loadingMore} = useSelector( state => state.user );
  const {status, message, type} = useSelector( state => state.user.notification );
  const [alert, setAlert] = React.useState(null);
  const [search, setSearch] = React.useState('');
  const [page, setPage] = React.useState(1);
  const [pageLimit, setPageLimit] = React.useState(40);
  const [isActiveLoadMore, setIsActiveLoadMore] = React.useState(true);
  const userPermission = JSON.parse(localStorage.getItem('user')).permission;
  const APP_USER_DETAILS = userPermission.includes(Permissions.APP_USER_DETAILS);
  const APP_USER_UPDATE = userPermission.includes(Permissions.APP_USER_UPDATE);
  const APP_USER_DELETE = userPermission.includes(Permissions.APP_USER_DELETE);

  useEffect(() => {
    dispatch(getUsers(page, false));
  }, [dispatch]);

  const loadMoreHandler = () => {
    setPage(page+1);
    dispatch(getUsers(page+1, true));
  };

  const hideAlert = () => { setAlert(null) };

  const searchHandler = () => {
    if(search.length > 1) {
      setIsActiveLoadMore(false);
      let newSearch = search.replace(/%/g, '');
      dispatch(searchUser(newSearch));
      return;
    }
    setAlert(
      <SweetAlert
        style={{ display: "block", marginTop: "-100px" }}
        title="The search text must contain at least 2 characters"
        onConfirm={() => hideAlert()}
        showConfirm={false}
      />
    );
    setTimeout(hideAlert, 2000);
  };

  const infoButton = (user) => {
      return (
        <Button key={user.id + 'info'}
          color="info"
          className={classes.actionButton}
          title={t('menu.user_profile')}
          onClick={()=> navigate('/user-profile', { state: {"user": user} })}
        >
          <Person className={classes.icon}/>
        </Button>
      );
  };

  const updateButton = (user) => {
    return (
      <Button
        color="success"
        className={classes.actionButton}
        title={t('edit')}
        onClick={()=> navigate('/add-app-user', { state: {"user": user} })}
      >
        <Edit className={classes.icon} />
      </Button>
    );
  };

  if (loading) return (<LoaderSpinner/>);

  const tableData = appUsers.results ?
    appUsers.results.map((value, index) => {
      let date = new Date(value.created_at);
      return ([
        index+1,
        value.name,
        value.email,
        value.phone,
        date.getFullYear()+'-'+(date.getMonth()+1)+'-'+date.getDate(),
        [
          APP_USER_DETAILS && infoButton(value),
          APP_USER_UPDATE && updateButton(value),
          APP_USER_DELETE && <DeleteButton key={value.id+'_delete'} action={deleteUser({...value})} setLimit={()=>setPageLimit(pageLimit-1)}/>
        ]
      ]);
    }) :  [[ ]];

  return  <GridContainer>
    {alert}
    <SnackbarCustom place="br" icon={AddAlert} color={type === 'success' ? 'success' : 'warning'} message = { message } open = { status }/>
    <GridItem xs={12}>
      <Card>
        <CardHeader >
          <GridContainer justifyContent="space-between" container>
            <GridItem md={3}>
              <CardHeader>
                <CardIcon color="rose"> <Assignment /> </CardIcon>
                <span className={classes.tableTitle}>{t('menu.app_users')} </span>
                {isActiveLoadMore && <span className={classes.tableCount} >  {`Sýni ${(page*pageLimit) < appUsers.count ? (page*pageLimit) : appUsers.count} af ${appUsers.count} notendum`} </span> }
              </CardHeader>
            </GridItem>
            <GridItem>
              <CustomInput
                inputProps={{
                  value: search,
                  onChange: event => setSearch(event.target.value),
                  onKeyPress: e => (e.key === 'Enter' && searchHandler()),
                  placeholder: t('search'),
                  inputProps: { "aria-label": "Search",  className: classes.searchInput }
                }}
              />
              <Button color="rose" aria-label="edit" justIcon size="sm" onClick={() => searchHandler()} >
                <Search className={classes.headerLinksSvg + " " + classes.searchIcon} />
              </Button>
            </GridItem>
            <GridItem>
              {
                userPermission.includes(Permissions.APP_USER_CREATE) &&
                <Link to="/add-app-user" >
                  <Button color="rose" className={classes.addButton}>{t('app_user.add_new_user')}</Button>
                </Link>
              }
            </GridItem>
          </GridContainer>
        </CardHeader>
        <CardBody>
          <Table
            striped
            tableHead={[ "#", t('name'), t('email'), t('phone'), t('created'), t('actions') ]}
            tableData={ tableData }
            customCellClasses={[classes.center, classes.right, classes.right]}
            customClassesForCells={[0, 4, 5]}
            customHeadCellClasses={[ classes.center, classes.right, classes.right]}
            customHeadClassesForCells={[0, 4, 5]}
          />
        </CardBody>
        <GridItem xs={12} >
          {isActiveLoadMore &&
            <Button color="rose" disabled={appUsers.results && appUsers.results.length < (page*pageLimit)} fullWidth onClick={() => loadMoreHandler()}>
              {t('load_more')} &nbsp; {loadingMore && <LoaderSpinner/>}
            </Button>
          }
        </GridItem>
      </Card>
    </GridItem>
  </GridContainer>
};

export default withTranslation('',{ withRef: true })(AppUser);


