import { transform } from "lodash";
import {
    primaryColor,
    dangerColor,
    successColor,
    defaultFont,
    whiteColor,
    grayColor,
} from "../../material-dashboard-pro-react.js";

const customInputStyle = {
    disabled: {
        "&:before": {
            borderColor: "transparent !important",
        },
    },
    underline: {
        "&:hover:not($disabled):before,&:before": {
            borderColor: grayColor[4] + "!important",
            borderWidth: "1px !important",
        },
        "&:after": {
            borderColor: primaryColor[0],
        },
        "& + p": {
            fontWeight: "400",
        },
    },
    underlineError: {
        "&:after": {
            borderColor: dangerColor[0],
        },
    },
    underlineSuccess: {
        "&:after": {
            borderColor: successColor[0],
        },
    },
    labelRoot: {
        ...defaultFont,
        fontWeight: "400",

        // transform: "translate(14px, 16px) scale(1)",
        // fontSize: "1.2rem",
    },

    labelRootError: {
        color: dangerColor[0] + " !important",
    },
    labelRootSuccess: {
        color: successColor[0] + " !important",
    },
    formControl: {
        position: "relative",
        marginTop: 16,
        padding: 0,
        verticalAlign: "unset",
        "& svg,& .fab,& .far,& .fal,& .fas,& .material-icons": {
            color: grayColor[14],
        },
    },
    whiteUnderline: {
        "&:hover:not($disabled):before,&:before": {
            backgroundColor: whiteColor,
        },
        "&:after": {
            backgroundColor: whiteColor,
        },
    },
    input: {
        color: grayColor[14],
        "&,&::placeholder": {
            // fontSize: "1.2rem",
            fontFamily: '"Livvic", "Helvetica", "Arial", sans-serif',
            fontWeight: "400",
            opacity: "1",
        },
        "&::placeholder": {
            color: grayColor[3],
        },
    },
    whiteInput: {
        "&,&::placeholder": {
            color: whiteColor,
            opacity: "1",
        },
    },
};

export default customInputStyle;
