import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {withTranslation} from 'react-i18next';

// material-ui icons
import {Assignment, AddAlert} from "@mui/icons-material";
import {createUseStyles} from "react-jss";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import SnackbarCustom from "components/Snackbar/Snackbar.js";

import styles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";

import {getActivityLogins} from "../../store/actions/activityLog";
import {LoaderSpinner} from "../../components/Loader";

const style = {
  tableTitle: {
    position: 'absolute',
    top: '10px',
    color: '#09b1c5',
    fontWeight: 'bold',
    fontSize: '18px'
  },
  tableCount: {
    position: 'absolute',
    top: '30px',
    color: '#000',
    fontSize: '14px'
  },
  ...styles
};

const useStyles = createUseStyles(style);

const Logs = ({ t }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const {activityLog, loading, loadingMore} = useSelector( state => state.activityLog );
  const {status, message, type} = useSelector( state => state.activityLog.notification );
  const [page, setPage] = React.useState(1);
  const [pageLimit, setPageLimit] = React.useState(40);
  const [isActiveLoadMore, setIsActiveLoadMore] = React.useState(true);
  const [alert, setAlert] = React.useState(null);

  useEffect(() => {
    dispatch(getActivityLogins(page, false));
  }, [dispatch]);

  const loadMoreHandler = () => {
    setPage(page+1);
    dispatch(getActivityLogins(page+1, true));
  };

  if (loading) return (<LoaderSpinner/>);

  const tableData = !activityLog.results ? [[ ]] :
    activityLog.results.map((value, index) => {
      let date = new Date(value.created_at);
      return ([
        index+1,
        value.name+'('+value.user_id+')',
        value.user_agent,
        value.ip_address,
        date.getFullYear()+'-'+(date.getMonth()+1)+'-'+date.getDate()
      ]);
    });

  return  <GridContainer>
    <div style={{zIndex: "1!important"}}> {alert} </div>
    <SnackbarCustom place="br" icon={AddAlert} color={type === 'success' ? 'success' : 'warning'} message={message} open={status}/>
    <GridItem xs={12}>
      <Card>
        <CardHeader color="info" icon>
          <GridContainer justifyContent="space-between" container>
            <GridItem md={3}>
              <CardIcon color="info"> <Assignment /> </CardIcon>
              {isActiveLoadMore && <span className={classes.tableCount} >  {`Sýni ${(page*pageLimit) < activityLog.count ? (page*pageLimit) : activityLog.count} af ${activityLog.count} innskráningum`} </span> }
            </GridItem>
          </GridContainer>
        </CardHeader>
        <CardBody>
          <Table
            striped
            tableHead={[ "ID",  'User', 'User agent', 'IP address', t('created') ]}
            tableData={ tableData }
            customCellClasses={[classes.center, classes.right, classes.right]}
            customClassesForCells={[0, 4, 5]}
            customHeadCellClasses={[classes.center, classes.right, classes.right ]}
            customHeadClassesForCells={[0, 4, 5]}
          />
        </CardBody>
        <GridItem xs={12} >
          {isActiveLoadMore &&
          <Button color="rose" disabled={activityLog.results && activityLog.results.length < (page*pageLimit)} fullWidth onClick={() => loadMoreHandler()}>
            {t('load_more')} &nbsp; {loadingMore && <LoaderSpinner/>}
          </Button>
          }
        </GridItem>
      </Card>
    </GridItem>
  </GridContainer>
};

export default withTranslation('',{ withRef: true })(Logs);