import React from "react";
import Datetime from "react-datetime";
import { withTranslation } from "react-i18next";

import moment from "moment";
import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";
import axios from "axios";
import SweetAlert from "react-bootstrap-sweetalert";

// @material-ui
import {
    Radio,
    RadioGroup,
    FormControlLabel,
    Switch,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    InputAdornment,
    TextareaAutosize,
    Checkbox,
} from "@mui/material";
import { ExpandMore, ExpandLess, AlarmOff, AlarmOn, Help } from "@mui/icons-material";

import styles from "./Step1.module.scss";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Autocomplete from "@mui/material/Autocomplete";
import { FlexContainer } from "components/FlexContainer/FlexContainer";
import SingleImageUpload from "components/SingleImageUpload/SingleImageUpload";
import HoverInfoBox from "components/HoverInfoBox/HoverInfoBox";

const activateOfferType = {
    email: "Netfang sem á að senda á",
    website: "Settu vefsíðu hér",
    promocode: "Settu kóða hér",
    phone: "Settu símanúmer hér",
};

class Step1 extends React.Component {
    constructor(props) {
        super(props);
        let extraImages =
            props.offerData.offer_images && props.offerData.offer_images.length > 0
                ? props.offerData.offer_images
                : [];
        // If props.offerData.image contains the same image as one of the extra images, remove it from the extra images
        if (props.offerData.image) {
            extraImages = extraImages.filter((x) => x.image !== props.offerData.image);
        }
        this.state = {
            alert: "",
            torgid_user_id: JSON.parse(localStorage.getItem("user")).user.torgid_user_id,
            id: props.offerData.id || "",
            title: props.offerData.title || "",
            titleState: props.offerData.title ? "success" : "",
            title_en: props.offerData.title_en || "",
            description: props.offerData.description || "",
            descriptionState: props.offerData.description ? "success" : "",
            description_en: props.offerData.description_en || "",
            discount: !props.offerData.discount
                ? ""
                : props.offerData.discount.includes("%")
                ? "discount"
                : props.offerData.discount.includes("kr.")
                ? "price"
                : props.offerData.discount,
            discountInput: !props.offerData.discount
                ? ""
                : props.offerData.discount.includes("%")
                ? props.offerData.discount.slice(0, -1)
                : props.offerData.discount.includes("kr.")
                ? props.offerData.discount.slice(0, -3)
                : "",
            // special_discount: !props.offerData.special_discount ? "" : props.offerData.special_discount.includes('%') ? props.offerData.special_discount.slice(0, -1) : props.offerData.special_discount,
            approved: props.offerData.id ? props.offerData.approved : false,
            image: props.offerData.image || "",
            images: extraImages,
            imagesState:
                props.offerData.offer_images && props.offerData.offer_images.length > 0
                    ? "success"
                    : props.offerData.image !== null
                    ? "success"
                    : "",
            action_type: props.offerData.action_type || "timer",
            action_value: props.offerData.action_value || "",
            promocode: props.offerData.promocode || "",
            offer_link: props.offerData.offer_link || "",
            is_active: props.offerData.id ? props.offerData.is_active : false,
            send_push_notification: props.offerData.id ? props.offerData.notified : false,
            send_push_notificationDisabled: !!(
                props.offerData.id && props.offerData.notified === true
            ),
            start_date: props.offerData.start_date || "",
            end_date: props.offerData.end_date || "",
            torgid_offer_active_times: [
                {
                    active_day: 0,
                    day: "mán.",
                    start_time: "08:00",
                    end_time: "18:00",
                    active: false,
                    active24: false,
                },
                {
                    active_day: 1,
                    day: "þri.",
                    start_time: "08:00",
                    end_time: "18:00",
                    active: false,
                    active24: false,
                },
                {
                    active_day: 2,
                    day: "mið.",
                    start_time: "08:00",
                    end_time: "18:00",
                    active: false,
                    active24: false,
                },
                {
                    active_day: 3,
                    day: "fim.",
                    start_time: "08:00",
                    end_time: "18:00",
                    active: false,
                    active24: false,
                },
                {
                    active_day: 4,
                    day: "fös.",
                    start_time: "08:00",
                    end_time: "18:00",
                    active: false,
                    active24: false,
                },
                {
                    active_day: 5,
                    day: "lau.",
                    start_time: "08:00",
                    end_time: "18:00",
                    active: false,
                    active24: false,
                },
                {
                    active_day: 6,
                    day: "sun.",
                    start_time: "08:00",
                    end_time: "18:00",
                    active: false,
                    active24: false,
                },
            ],
            active_times_status: false,
            torgid_special_company_id: props.offerData.torgid_special_company
                ? props.offerData.torgid_special_company.id
                : 0,
            companyId: props.offerData.torgid_company_id ? props.offerData.torgid_company_id : null,
            companyName: props.offerData.torgid_company_id
                ? props.offerData.torgid_company.name
                : null,
            companyState: props.offerData.torgid_company_id ? "success" : "",
            torgid_special_company_ids: !props.offerData.id
                ? []
                : props.offerData.group_ids
                ? props.offerData.group_ids.split(",").map((x) => +x)
                : props.offerData.torgid_special_company_id
                ? [props.offerData.torgid_special_company_id]
                : [0],
            groupState: props.offerData.group_ids
                ? "success"
                : props.offerData.id && props.offerData.group_ids === null
                ? "success"
                : "",
        };
    }

    componentDidMount() {
        if (this.props.offerData.torgid_offer_active_times) {
            let activeDaysState = [...this.state.torgid_offer_active_times];
            this.props.offerData.torgid_offer_active_times.forEach((value) => {
                activeDaysState[value.active_day].active = true;
                // activeDaysState[value.active_day].active24 = false;
                activeDaysState[value.active_day].start_time = value.start_time;
                activeDaysState[value.active_day].end_time = value.end_time;
            });
            this.setState({ torgid_offer_active_times: activeDaysState });
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (
            !props.offerData.id &&
            state.companyId &&
            props.data2 &&
            props.data2.results &&
            state.active_times_status
        ) {
            let activeDays = [
                {
                    active_day: 0,
                    day: "mán.",
                    start_time: "08:00",
                    end_time: "18:00",
                    active: false,
                    active24: false,
                },
                {
                    active_day: 1,
                    day: "þri.",
                    start_time: "08:00",
                    end_time: "18:00",
                    active: false,
                    active24: false,
                },
                {
                    active_day: 2,
                    day: "mið.",
                    start_time: "08:00",
                    end_time: "18:00",
                    active: false,
                    active24: false,
                },
                {
                    active_day: 3,
                    day: "fim.",
                    start_time: "08:00",
                    end_time: "18:00",
                    active: false,
                    active24: false,
                },
                {
                    active_day: 4,
                    day: "fös.",
                    start_time: "08:00",
                    end_time: "18:00",
                    active: false,
                    active24: false,
                },
                {
                    active_day: 5,
                    day: "lau.",
                    start_time: "08:00",
                    end_time: "18:00",
                    active: false,
                    active24: false,
                },
                {
                    active_day: 6,
                    day: "sun.",
                    start_time: "08:00",
                    end_time: "18:00",
                    active: false,
                    active24: false,
                },
            ];
            if (props.data2.results.torgid_company_locations.length > 0) {
                props.data2.results.torgid_company_locations[0].torgid_company_openings.forEach(
                    (value) => {
                        activeDays[value.day].active = true;
                        activeDays[value.day].start_time = value.open_time;
                        activeDays[value.day].end_time = value.close_time;
                    }
                );
            }
            return { torgid_offer_active_times: activeDays, active_times_status: false };
        }
        return null;
    }

    sendState() {
        return this.state;
    }

    verifyLength(value, length) {
        return value.length >= length;
    }

    handleChange = (data, type) => {
        this.setState({ [type]: data });
    };

    handleChangeOption = (event) => {
        const {
            target: { value },
        } = event;
        let last = value[value.length - 1];
        if (last === 0 || last === 1 || last === 3) {
            this.setState({ torgid_special_company_ids: [last] });
        } else {
            if (value.indexOf(0) > -1 || value.indexOf(1) > -1 || value.indexOf(3) > -1) {
                this.setState({ torgid_special_company_ids: [last] });
            } else {
                this.setState({ torgid_special_company_ids: value });
            }
        }
        this.setState({ groupState: "success" });
    };

    handleChangeGroup = (data) => {
        this.setState({ torgid_special_company_id: data });
        this.setState({ groupState: "success" });
    };

    validateImageFile = (file) => {
        const fileTypes = ["image/jpeg", "image/jpg", "image/png"];
        if (!fileTypes.includes(file.type)) {
            return false;
        }
        // Validate file size
        if (file.size > 800000) {
            return false;
        }

        return true;
    };

    onUpload(data, index, id) {
        let images = this.state.images;
        // If index is more than the length of the images array, then it is a new image
        if (index >= images.length) {
            images.push({
                id: null,
                image: data,
                sort_order: this.state.images.length + 2,
            });
        }

        if (
            images[index].image &&
            images[index].image !== "" &&
            images?.[index]?.image?.includes("http")
        ) {
            images[index].deleted = true;
            images.push({
                id: null,
                image: data,
                sort_order: this.state.images.length + 2,
            });
        } else {
            images[index].id = id;
            images[index].image = data;
            images[index].sort_order = index + 2;
        }

        this.setState({
            images: images,
            imagesState: "success",
        });
    }

    onUploadMainImage(data) {
        this.setState({
            image: data,
            imagesState: "success",
        });
    }

    changeImageOrder(currentIndex, newIndex) {
        let images = this.state.images;
        let currentItem = images[currentIndex];
        images.splice(currentIndex, 1);
        images.splice(newIndex, 0, currentItem);
        this.setState({ images: images });
    }

    deleteImageItem(index) {
        let images = [...this.state.images];

        if (images[index].id) {
            images[index].deleted = true;
            images.splice(index, 1);
        } else {
            images.splice(index, 1);
        }

        this.setState({ images: images });
    }

    addNewImage = () => {
        this.setState({
            images: [
                ...this.state.images,
                {
                    id: null,
                    image: "",
                    sort_order: this.state.images.length + 2,
                },
            ],
        });
    };

    change(event, stateName, type, stateNameEqualTo) {
        if (type === "length") {
            if (this.verifyLength(event.target.value, stateNameEqualTo)) {
                this.setState({ [stateName + "State"]: "success" });
            } else {
                this.setState({ [stateName + "State"]: "error" });
            }
        }
        this.setState({ [stateName]: event.target.value });
    }

    isValidated() {
        if (
            this.state.titleState === "success" &&
            this.state.descriptionState === "success" &&
            this.state.companyState === "success" &&
            this.state.imagesState === "success" &&
            this.state.groupState === "success"
        ) {
            return true;
        } else {
            if (this.state.titleState !== "success") {
                this.setState({ titleState: "error" });
            }
            if (this.state.descriptionState !== "success") {
                this.setState({ descriptionState: "error" });
            }
            if (this.state.companyState !== "success") {
                this.setState({ companyState: "error" });
            }
            if (this.state.imagesState !== "success") {
                this.setState({ imagesState: "error" });
            }
            if (this.state.groupState !== "success") {
                this.setState({ groupState: "error" });
            }
        }
        return false;
    }

    hideAlert = () => {
        this.setState({ alert: null });
    };

    autoTranslate = (state, type, fromLn, toLn) => {
        let textOld = this.state[state];
        let text = textOld.replace(/&/g, "");
        axios
            .get(
                `https://translation.googleapis.com/language/translate/v2?key=AIzaSyA5AF_VTEbn4TXQ2uMWsaEx0Al53mT7aOU&q=${text}&source=${fromLn}&target=${toLn}`
            )
            .then((response) => {
                if (response.status === 200) {
                    let translatedText = response.data.data.translations[0].translatedText;
                    this.setState({
                        [type]: translatedText.replace(new RegExp("&#39;", "g"), "'"),
                    });
                } else {
                    this.setState({
                        alert: (
                            <SweetAlert
                                title={response.message}
                                onConfirm={() => this.hideAlert()}
                                showConfirm={false}
                                closeOnClickOutside={true}
                            />
                        ),
                    });
                    setTimeout(this.hideAlert, 2000);
                }
            });
    };

    handleActiveTime(index) {
        let activeTimes = [...this.state.torgid_offer_active_times];
        activeTimes[index] = {
            active_day: index,
            day: activeTimes[index].day,
            start_time: activeTimes[index].start_time,
            end_time: activeTimes[index].end_time,
            active: !activeTimes[index].active,
            active24: activeTimes[index].active24,
        };
        this.setState({ torgid_offer_active_times: activeTimes });
    }

    handleActiveTimeDef(index) {
        let activeTimes = [...this.state.torgid_offer_active_times];
        activeTimes[index] = {
            active_day: index,
            day: activeTimes[index].day,
            start_time: "00:00",
            end_time: "23:59",
            active: true,
            active24: !activeTimes[index].active24,
        };
        this.setState({ torgid_offer_active_times: activeTimes });
    }

    handleChangeTime = (e, index, type) => {
        let val = e ? e.format("HH:mm") : "00:00";
        let activeTimes = [...this.state.torgid_offer_active_times];
        activeTimes[index] = {
            active_day: index,
            day: activeTimes[index].day,
            start_time: type === "st" ? val : activeTimes[index].start_time,
            end_time: type === "et" ? val : activeTimes[index].end_time,
            active: true,
            active24: false,
        };
        this.setState({ torgid_offer_active_times: activeTimes });
    };

    handleChangeCompany = (data) => {
        if (!data && data.length === 0) return;

        if (!this.state.id) {
            this.props.getData(data.id);
        }
        this.setState({
            companyId: data.id,
            companyName: data.name,
            companyState: "success",
            active_times_status: true,
        });
    };

    handleDeleteMainImage = () => {
        // get the first element of the images array
        let images = this.state.images;
        if (images.length > 0) {
            // Remove the first element of the images array
            this.setState({ image: images[0].image });
            images.shift();
        } else {
            this.setState({ image: "" });
        }
    };

    render() {
        const { classes, data, t, companies } = this.props;

        const renderActiveTime =
            this.state.torgid_offer_active_times &&
            this.state.torgid_offer_active_times.map((value, index) => {
                const startTime = moment(value.start_time, "H:mm");
                const endTime = moment(value.end_time, "H:mm");
                return (
                    <GridItem key={index} xs={12} sm={1}>
                        <Button
                            key={index + "btn"}
                            color={value.active ? "success" : "default"}
                            size="sm"
                            onClick={() => this.handleActiveTime(index)}
                        >
                            {this.props.t(value.day)}
                            {value.active ? (
                                <ExpandLess style={{ marginRight: 0 }} />
                            ) : (
                                <ExpandMore style={{ marginRight: 0 }} />
                            )}
                        </Button>
                        {value.active && value.active24 ? (
                            <AlarmOn onClick={() => this.handleActiveTimeDef(index)} />
                        ) : (
                            <AlarmOff onClick={() => this.handleActiveTimeDef(index)} />
                        )}
                        {value.active && (
                            <GridItem key={index} xs={12} sm={6}>
                                <TimePicker
                                    style={{ width: 80, left: "-10px" }}
                                    showSecond={false}
                                    value={startTime}
                                    placeholder="Start"
                                    use12Hours={false}
                                    minuteStep={10}
                                    onChange={(e) => this.handleChangeTime(e, index, "st")}
                                />
                                <TimePicker
                                    style={{ width: 80, left: "-10px" }}
                                    showSecond={false}
                                    value={endTime}
                                    placeholder="End"
                                    use12Hours={false}
                                    minuteStep={10}
                                    onChange={(e) => this.handleChangeTime(e, index, "et")}
                                />
                            </GridItem>
                        )}
                    </GridItem>
                );
            });

        const options =
            companies.results &&
            companies.results
                .filter((item) => item.id !== 1305)
                .map((option) => {
                    const firstLetter = option.name.charAt(0).toUpperCase();
                    return {
                        firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
                        ...option,
                    };
                });

        return (
            <GridContainer
                justifyContent="space-between"
                style={{ padding: "0px 20px" }}
                className={styles.container}
            >
                {this.state.alert}

                <GridItem xs={12} sm={6}>
                    <CustomInput
                        success={this.state.titleState === "success"}
                        error={this.state.titleState === "error"}
                        labelText={<span>{t("offer_title")}</span>}
                        id="title"
                        formControlProps={{ fullWidth: true }}
                        inputProps={{
                            value: this.state.title,
                            onChange: (event) => this.change(event, "title", "length", 5),
                        }}
                    />
                </GridItem>

                <GridItem xs={12} sm={6}>
                    <CustomInput
                        labelText={<span> {t("offer_title_en")} </span>}
                        id="title_en"
                        formControlProps={{ fullWidth: true }}
                        inputProps={{
                            value: this.state.title_en,
                            onChange: (event) => this.change(event, "title_en"),
                        }}
                        helperText={
                            this.state.title ? (
                                <Button
                                    color="success"
                                    size="sm"
                                    onClick={() =>
                                        this.autoTranslate("title", "title_en", "is", "en")
                                    }
                                >
                                    {t("translate_is_en")}
                                </Button>
                            ) : (
                                ""
                            )
                        }
                    />
                </GridItem>

                <GridItem xs={12} sm={12}>
                    <FlexContainer
                        justifyContent="space-between"
                        style={{ margin: "0px 0px", flexGrow: 1 }}
                    >
                        <FlexContainer column style={{ flex: 1 }}>
                            <FlexContainer alignItems="center" justifyContent="center">
                                <div style={{ marginRight: 4, lineHeight: "1rem" }}>Myndir</div>
                                <HoverInfoBox
                                    hoverComponent={
                                        <Help
                                            style={{
                                                lineHeight: "1rem",
                                                color: "gray",
                                                height: "1rem",
                                                width: "1rem",
                                            }}
                                        />
                                    }
                                    content={
                                        <div
                                            style={{
                                                fontSize: "0.8rem",
                                                lineHeight: "1rem",
                                                width: 300,
                                            }}
                                        >
                                            <p>
                                                Ákjósanleg stærð aðalmyndar er 900x600px eða
                                                hlutföllin 3:2. Má ekki vera stærri en 800 KB.
                                            </p>

                                            <p>
                                                Best er að texti eða aðrar mikilvægar upplýsingar
                                                fari ekki inn á bláa/hvíta svæðið.
                                            </p>
                                            <p>
                                                Það svæði birtist ekki í öllum tilboðsútgáfum í
                                                appinu.
                                            </p>
                                        </div>
                                    }
                                />
                            </FlexContainer>
                            <div className={styles.imagesContainer}>
                                <div className={styles.mainImageContainer}>
                                    <SingleImageUpload
                                        image={this.state.image}
                                        onUpload={(data) => this.onUploadMainImage(data)}
                                        onDelete={() => this.handleDeleteMainImage()}
                                        style={{
                                            aspectRatio: "3/2",
                                            minWidth: 150,
                                        }}
                                        overlay={
                                            <>
                                                <div
                                                    style={{
                                                        position: "absolute",
                                                        top: 0,
                                                        left: 0,
                                                        aspectRatio: "50/200",
                                                        background:
                                                            "repeating-linear-gradient(45deg,#007AAA, #007AAA 8px, white 8px, white 16px)",
                                                        opacity: 0.4,
                                                        height: "100%",
                                                    }}
                                                />
                                                <div
                                                    style={{
                                                        position: "absolute",
                                                        top: 0,
                                                        right: 0,
                                                        aspectRatio: "50/200",
                                                        background:
                                                            "repeating-linear-gradient(315deg,#007AAA, #007AAA 8px, white 8px, white 16px)",
                                                        opacity: 0.4,
                                                        height: "100%",
                                                    }}
                                                />
                                            </>
                                        }
                                    />
                                </div>
                                <div
                                    style={{
                                        display: "grid",
                                        width: "100%",
                                        gridTemplateColumns:
                                            "repeat(auto-fill, minmax(150px, 1fr))",
                                    }}
                                >
                                    {this.state.images.map((item, index) => {
                                        return (
                                            <div>
                                                <SingleImageUpload
                                                    style={{
                                                        flexGrow: 1,
                                                        width: "auto",
                                                    }}
                                                    image={item.image}
                                                    imgHolder={item.image || null}
                                                    index={index}
                                                    id={item.id}
                                                    onChangeOrder={(currentIndex, newIndex) =>
                                                        this.changeImageOrder(
                                                            currentIndex - 1,
                                                            newIndex - 1
                                                        )
                                                    }
                                                    onUpload={(data, i, id) =>
                                                        this.onUpload(data, i - 1, id)
                                                    }
                                                    onDelete={this.deleteImageItem.bind(this)}
                                                    disabledDeleteBtn={
                                                        this.state.images.length === 1
                                                    }
                                                    errorStatus={this.state.imagesState}
                                                />
                                            </div>
                                        );
                                    })}
                                    {this.state.image !== null &&
                                        this.state.image !== "" &&
                                        this.state.image !== undefined && (
                                            <div>
                                                <SingleImageUpload
                                                    style={{
                                                        margin: 2,
                                                        flexGrow: 1,
                                                        width: "100%",
                                                        height: "auto",
                                                        aspectRatio: 3 / 2,
                                                    }}
                                                    onUpload={(data, i, id) =>
                                                        this.onUpload(
                                                            data,
                                                            this.state.images.length,
                                                            id
                                                        )
                                                    }
                                                />
                                            </div>
                                        )}
                                </div>
                            </div>
                        </FlexContainer>
                    </FlexContainer>
                </GridItem>
                <GridItem xs={12} sm={6}>
                    <Autocomplete
                        id={"companies"}
                        onChange={(event, newValue) => {
                            this.handleChangeCompany(newValue);
                        }}
                        value={
                            this.state.companyId
                                ? { id: this.state.companyId, name: this.state.companyName }
                                : null
                        }
                        options={
                            options
                                ? options.sort(
                                      (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
                                  )
                                : []
                        }
                        groupBy={(option) => option.firstLetter}
                        getOptionLabel={(option) => option.name}
                        getOptionSelected={(option, value) => option.id === value.id}
                        style={{ marginTop: 10 }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={t("companies")}
                                variant="standard"
                                error={this.state.companyState === "error"}
                            />
                        )}
                    />
                </GridItem>

                <GridItem xs={12} sm={6}>
                    {data.results && (
                        <FormControl
                            fullWidth
                            className={classes.selectFormControl}
                            style={{ marginTop: "10px" }}
                            error={this.state.groupState === "error"}
                        >
                            <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
                                {t("special_companies")}
                            </InputLabel>
                            <Select
                                MenuProps={{
                                    className: classes.selectMenu,
                                    style: { zIndex: 35001 },
                                }}
                                classes={{ select: classes.select }}
                                value={this.state.torgid_special_company_ids}
                                onChange={(e) => this.handleChangeOption(e)}
                                // onChange={e => this.handleChange(e.target.value, 'torgid_special_company_id')}
                                inputProps={{ name: "special_company", id: "roles-select" }}
                                multiple
                                variant="standard"
                                renderValue={(selected) =>
                                    selected.map((value, index) => {
                                        return value === 0
                                            ? t("offer.special_companies_Default")
                                            : (data.results.find((x) => x.id === value)?.name ||
                                                  "") + ", ";
                                    })
                                }
                            >
                                <MenuItem
                                    key={"torgid_special_company_id_null"}
                                    value={0}
                                    classes={{
                                        root: classes.selectMenuItem,
                                        selected: classes.selectMenuItemSelected,
                                    }}
                                >
                                    <RadioGroup
                                        row
                                        aria-labelledby="default_opt"
                                        value={
                                            this.state.torgid_special_company_ids.indexOf(0) > -1
                                                ? 0
                                                : null
                                        }
                                        name="row-group_0"
                                        className={classes.special_option}
                                    >
                                        <FormControlLabel
                                            className={classes.special_option_label}
                                            value={0}
                                            control={<Radio />}
                                            label={t("offer.special_companies_Default")}
                                        />
                                    </RadioGroup>
                                </MenuItem>
                                <MenuItem
                                    key={"torgid_special_company_id_lyk"}
                                    value={1}
                                    classes={{
                                        root: classes.selectMenuItem,
                                        selected: classes.selectMenuItemSelected,
                                    }}
                                >
                                    <RadioGroup
                                        row
                                        aria-labelledby="default_opt"
                                        value={
                                            this.state.torgid_special_company_ids.indexOf(1) > -1
                                                ? 1
                                                : null
                                        }
                                        name="row-group_1"
                                        className={classes.special_option}
                                    >
                                        <FormControlLabel
                                            className={classes.special_option_label}
                                            value={1}
                                            control={<Radio />}
                                            label={t("offer.special_companies_Lykilkjör")}
                                        />
                                    </RadioGroup>
                                </MenuItem>
                                <MenuItem
                                    key={"torgid_special_company_id_b2b"}
                                    value={3}
                                    classes={{
                                        root: classes.selectMenuItem,
                                        selected: classes.selectMenuItemSelected,
                                    }}
                                >
                                    <RadioGroup
                                        row
                                        aria-labelledby="default_opt"
                                        value={
                                            this.state.torgid_special_company_ids.indexOf(3) > -1
                                                ? 3
                                                : null
                                        }
                                        name="row-group_3"
                                        className={classes.special_option}
                                    >
                                        <FormControlLabel
                                            className={classes.special_option_label}
                                            value={3}
                                            control={<Radio />}
                                            label={t("offer.special_companies_B2B")}
                                        />
                                    </RadioGroup>
                                </MenuItem>

                                {data.results &&
                                    data.results
                                        .filter(
                                            (item) =>
                                                item.id !== 1 &&
                                                item.id !== 3 &&
                                                item.hidden !== true
                                        )
                                        .map((value, index) => (
                                            <MenuItem key={value.id} value={value.id}>
                                                <Checkbox
                                                    checked={
                                                        this.state.torgid_special_company_ids.indexOf(
                                                            value.id
                                                        ) > -1
                                                    }
                                                />
                                                {value.name}
                                            </MenuItem>
                                        ))}
                            </Select>
                        </FormControl>
                    )}
                </GridItem>

                <GridItem xs={12} sm={6} className={classes.paddingRow}>
                    <TextareaAutosize
                        className={`
              ${classes.txtArea} 
              ${this.state.descriptionState === "success" && classes.txtAreaSuccess} 
              ${this.state.descriptionState === "error" && classes.txtAreaError}
            `}
                        minRows={4}
                        maxRows={8}
                        id="description"
                        aria-label="description"
                        placeholder={t("offer_desc_is")}
                        value={this.state.description}
                        onChange={(event) => this.change(event, "description", "length", 10)}
                    />
                </GridItem>

                <GridItem xs={12} sm={6} className={classes.paddingRow}>
                    <TextareaAutosize
                        className={classes.txtArea}
                        minRows={4}
                        maxRows={8}
                        id="description_en"
                        aria-label="description_en"
                        placeholder={t("offer_desc_en")}
                        value={this.state.description_en}
                        onChange={(event) => this.change(event, "description_en")}
                    />
                    {!this.state.description ? (
                        <Button color="default" size="sm">
                            {t("please_fill_description")}
                        </Button>
                    ) : (
                        <Button
                            color="success"
                            size="sm"
                            onClick={() =>
                                this.autoTranslate("description", "description_en", "is", "en")
                            }
                        >
                            {t("translate_is_en")}
                        </Button>
                    )}
                </GridItem>

                <GridItem xs={12} sm={3}>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={this.state.approved}
                                onChange={(e) => this.handleChange(e.target.checked, "approved")}
                                value="false"
                            />
                        }
                        classes={{ label: classes.label }}
                        label={t("approved")}
                    />
                </GridItem>

                <GridItem xs={12} sm={3}>
                    <FormControl>
                        <Datetime
                            value={this.state.start_date}
                            onChange={(e) => this.handleChange(e, "start_date")}
                            timeFormat={false}
                            className={classes.datePicker}
                            inputProps={{ placeholder: t("offer_date_from") }}
                            dateFormat="yyyy-MM-D"
                            classes={{ label: classes.label }}
                        />
                    </FormControl>
                </GridItem>

                <GridItem xs={12} sm={3}>
                    <FormControl>
                        <Datetime
                            value={this.state.end_date}
                            onChange={(e) => this.handleChange(e, "end_date")}
                            timeFormat={false}
                            className={classes.datePicker}
                            inputProps={{ placeholder: t("offer_date_till") }}
                            dateFormat="yyyy-MM-D"
                        />
                    </FormControl>
                </GridItem>

                <GridItem xs={12} sm={3}>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={this.state.send_push_notification}
                                onChange={(e) =>
                                    this.handleChange(e.target.checked, "send_push_notification")
                                }
                                value="false"
                                disabled={this.state.send_push_notificationDisabled}
                            />
                        }
                        classes={{ label: classes.label }}
                        label="Send notification"
                    />
                </GridItem>

                <GridItem xs={12} sm={6} style={{ margin: "10px 0 20px 0" }}>
                    <FormControl fullWidth className={classes.selectFormControl} variant="standard">
                        <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
                            {t("offer_new_discount")}
                        </InputLabel>
                        <Select
                            MenuProps={{ className: classes.selectMenu }}
                            classes={{ select: classes.select }}
                            value={this.state.discount}
                            onChange={(e) => this.handleChange(e.target.value, "discount")}
                            inputProps={{ name: "discount", id: "discount" }}
                        >
                            <MenuItem classes={{ selected: classes.dSelected }} value="discount">
                                {t("offer_discount")}
                            </MenuItem>
                            <MenuItem classes={{ selected: classes.dSelected }} value="price">
                                {t("offer_price")}
                            </MenuItem>
                            <MenuItem classes={{ selected: classes.dSelected }} value="2 fyrir 1">
                                {t("offer_2_for_1")}
                            </MenuItem>
                            <MenuItem classes={{ selected: classes.dSelected }} value="sérkjör">
                                {t("offer_special")}
                            </MenuItem>
                            <MenuItem classes={{ selected: classes.dSelected }} value="tilkynning">
                                {t("offer_announcement")}
                            </MenuItem>
                        </Select>
                    </FormControl>
                    {(this.state.discount === "discount" || this.state.discount === "price") && (
                        <CustomInput
                            labelText={<span> {t("offer_displayed_discount")} </span>}
                            id="discount_input"
                            formControlProps={{ fullWidth: true }}
                            inputProps={{
                                value: this.state.discountInput,
                                onChange: (event) => this.change(event, "discountInput"),
                                endAdornment: (
                                    <InputAdornment
                                        position="end"
                                        className={classes.inputAdornment}
                                    >
                                        {this.state.discount === "discount" ? "%" : "kr."}
                                    </InputAdornment>
                                ),
                            }}
                        />
                    )}

                    {/*{this.state.discount === 'discount' &&*/}
                    {/*<CustomInput*/}
                    {/*  labelText={ <span> {t('offer_discount_spec_discount')} </span> }*/}
                    {/*  id="discount_input"*/}
                    {/*  formControlProps={{ fullWidth: true }}*/}
                    {/*  inputProps={{*/}
                    {/*    value: this.state.special_discount,*/}
                    {/*    onChange: event => this.change(event, "special_discount"),*/}
                    {/*    endAdornment: (*/}
                    {/*      <InputAdornment position="end" className={classes.inputAdornment}>%</InputAdornment>*/}
                    {/*    )*/}
                    {/*  }}*/}
                    {/*/>*/}
                    {/*}*/}
                </GridItem>

                <GridItem xs={12} sm={6} style={{ margin: "10px 0 20px 0" }}>
                    <FormControl fullWidth className={classes.selectFormControl} variant="standard">
                        <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
                            {t("activate_offer")}
                        </InputLabel>
                        <Select
                            MenuProps={{ className: classes.selectMenu }}
                            classes={{ select: classes.select }}
                            value={this.state.action_type}
                            onChange={(e) => this.handleChange(e.target.value, "action_type")}
                            inputProps={{ name: "action_type", id: "action_type" }}
                        >
                            <MenuItem classes={{ selected: classes.dSelected }} value="timer">
                                {t("offer_timer")}
                            </MenuItem>
                            <MenuItem classes={{ selected: classes.dSelected }} value="promocode">
                                {t("offer_promocode")}
                            </MenuItem>
                            <MenuItem classes={{ selected: classes.dSelected }} value="email">
                                {t("offer_email")}
                            </MenuItem>
                            <MenuItem classes={{ selected: classes.dSelected }} value="phone">
                                {t("offer_phone")}
                            </MenuItem>
                            <MenuItem classes={{ selected: classes.dSelected }} value="website">
                                {t("offer_website")}
                            </MenuItem>
                            <MenuItem classes={{ selected: classes.dSelected }} value="barcode">
                                {t("offer_barcode")}
                            </MenuItem>
                            <MenuItem classes={{ selected: classes.dSelected }} value="qrcode">
                                {t("offer_qr_code")}
                            </MenuItem>
                        </Select>
                    </FormControl>
                    {(this.state.action_value || this.state.action_type) &&
                        this.state.action_type !== "timer" && (
                            <CustomInput
                                labelText={
                                    <span>
                                        {activateOfferType.hasOwnProperty(this.state.action_type)
                                            ? t("offer_active_" + this.state.action_type)
                                            : "Value"}
                                    </span>
                                }
                                id="action_value"
                                formControlProps={{ fullWidth: true }}
                                inputProps={{
                                    value: this.state.action_value,
                                    onChange: (event) => this.change(event, "action_value"),
                                }}
                            />
                        )}
                    {this.state.action_type === "promocode" && (
                        <CustomInput
                            labelText={<span> {t("offer_link")} </span>}
                            id="offer_link"
                            formControlProps={{ fullWidth: true }}
                            inputProps={{
                                value: this.state.offer_link,
                                onChange: (event) => this.change(event, "offer_link"),
                            }}
                        />
                    )}
                </GridItem>

                <GridItem xs={12} sm={12}>
                    <hr style={{ marginBottom: 0 }} />
                </GridItem>
                <GridItem
                    xs={12}
                    sm={12}
                    style={{ textAlign: "center", fontWeight: "normal", color: "#00acc1" }}
                >
                    {t("offer.select_days_and_time")}
                </GridItem>

                <GridItem xs={12} sm={11}>
                    <GridContainer justifyContent="space-between" alignItems="stretch">
                        {renderActiveTime}
                    </GridContainer>
                </GridItem>
            </GridContainer>
        );
    }
}

export default withTranslation("", { withRef: true })(Step1);
