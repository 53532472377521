import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

// core components
import Wizard from "../../components/Wizard/Wizard.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import GridItem from "../../components/Grid/GridItem.js";
import SnackbarCustom from "../../components/Snackbar/Snackbar.js";

import Step1 from "./AddUserSteps/Step1.js";

import { createUpdateUser } from "../../store/actions/user";
import { AddAlert } from "@mui/icons-material";
import { getRegions } from "../../store/actions/region";

import wizardStyle from "assets/jss/material-dashboard-pro-react/components/wizardStyle.js";
const style = {
    inputAdornmentIcon: {
        colorDisabled: "fff",
    },
    disabledInputIcon: {
        colorDisabled: "eee",
    },
    inputAdornment: {
        position: "relative",
    },
    subscribed: {
        marginTop: "10px",
    },
    selectMenuItemSelected: {
        color: "#DE2C48",
    },
    selectMenu: {
        color: "#DE2C48",
    },
    select: {
        color: "#4c535a",
    },
    datePicker: {
        marginTop: "15px",
    },
    marginTop: {
        marginTop: "10px",
    },
    ...wizardStyle,
};

const useStyles = createUseStyles(style);

const AddUser = ({ t }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { state } = useLocation();
    const classes = useStyles();
    const isUpdating = !!(state && state.user);
    const { regions } = useSelector((state) => state.region);
    const { status, message, type } = useSelector((state) => state.user.notification);
    const onSubmit = (userData) => {
        dispatch(createUpdateUser({ update: isUpdating, ...userData }, navigate));
    };

    useEffect(() => {
        dispatch(getRegions());
    }, [dispatch]);

    return (
        <GridContainer justifyContent="center">
            <GridItem xs={12} sm={10}>
                <SnackbarCustom
                    place="br"
                    icon={AddAlert}
                    color={type === "success" ? "success" : "warning"}
                    message={message}
                    open={status}
                />

                <Wizard
                    classes={classes}
                    validate
                    history={navigate}
                    user={(state && state.user) || {}}
                    data={regions}
                    steps={[
                        {
                            stepName: t("app_user_add.about"),
                            stepComponent: Step1,
                            stepId: "about",
                        },
                    ]}
                    title={
                        isUpdating
                            ? t("app_user_add.update_profile")
                            : t("app_user_add.build_profile")
                    }
                    nextButtonText={t("next")}
                    cancelButtonText={t("cancel")}
                    previousButtonText={t("previous")}
                    finishButtonText={t("finish")}
                    finishButtonClick={onSubmit}
                />
            </GridItem>
        </GridContainer>
    );
};

export default withTranslation("", { withRef: true })(AddUser);
