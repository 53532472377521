import React from "react";
import { withTranslation } from "react-i18next";
import "rc-time-picker/assets/index.css";
import { FormControl, InputLabel, Select, MenuItem, InputAdornment } from "@mui/material";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import PictureUpload from "components/CustomUpload/PictureUpload.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import defaultImage from "assets/img/placeholder.jpg";
import { ColorLens } from "@mui/icons-material";
import ColorPicker from "../../../components/ColorPicker/ColorPicker";

class Step1 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            alert: "",
            id: props.data.id || null,
            name: props.data.name || "",
            nameState: props.data.name ? "success" : "",
            display_name: props.data.display_name || "",
            kennitala: props.data.kennitala || "",
            logo: props.data.logo || null,
            hidden: props.data.hidden || false,
            card_logo_location: props.data.card_logo_location || "",
            card_colour: props.data.card_colour || "",
            card_colour_default: props.data.card_colour || "#4c535a",
            card_image: props.data.card_image || null,
            has_membership_card: props.data.has_membership_card || false,
            type: props.data.type || "regular",
            background: props.data
                ? props.data.card_colour
                    ? "color"
                    : props.data.card_image
                    ? "image"
                    : ""
                : "",
            font_colour: props.data.font_colour || "",
            font_colour_default: props.data.font_colour || "#4c535a",
            attached_group_ids:
                props.data.attached_groups && Object.keys(props.data2).length > 0
                    ? props.data.attached_groups.map((item) =>
                          props.data2.results.find((f) => f.id === item.attached_group_id)
                      )
                    : [],
        };
    }

    static getDerivedStateFromProps(props, state) {
        if (
            Object.keys(props.data2).length > 0 &&
            props.data.attached_groups &&
            props.data.attached_groups.length > 0 &&
            state.attached_group_ids.length === 0
        ) {
            return {
                ...state,
                attached_group_ids: props.data.attached_groups.map((item) =>
                    props.data2.results.find((f) => f.id === item.attached_group_id)
                ),
            };
        }
        return null;
    }

    sendState() {
        return this.state;
    }

    verifyLength(value, length) {
        return value.length >= length;
    }

    change(stateName, data, type, stateNameEqualTo) {
        if (type === "length") {
            if (this.verifyLength(data, stateNameEqualTo)) {
                this.setState({ [stateName + "State"]: "success" });
            } else {
                this.setState({ [stateName + "State"]: "error" });
            }
        }
        this.setState({ [stateName]: data });
    }

    changeGroupType(data) {
        if (data === "lite") {
            this.setState({
                has_membership_card: false,
                hidden: true,
                type: data,
            });
        } else {
            this.setState({
                type: data,
            });
        }
    }

    handleChangeAttachGroup(data) {
        let tempGroupIds = this.state.attached_group_ids;
        tempGroupIds.push(data.target.value);

        this.setState({
            attached_group_ids: tempGroupIds,
        });
    }

    handleDeleteAttachedGroup = (data) => {
        let tempGroupIds = this.state.attached_group_ids;
        const currentIndex = tempGroupIds.findIndex((f) => f.id === data.id);
        if (currentIndex >= 0) {
            tempGroupIds.splice(currentIndex, 1);
        }
        this.setState({
            attached_group_ids: tempGroupIds,
        });
    };

    isValidated() {
        if (this.state.nameState === "success") {
            return true;
        } else {
            this.setState({ nameState: "error" });
        }
        return false;
    }

    hideAlert = () => {
        this.setState({ alert: null });
    };

    changeColor = (state, color) => {
        this.change(state, color);
        this.hideAlert();
    };

    chooseColor = (stateName) => {
        this.setState({
            alert: (
                <ColorPicker
                    confirm={(color) => this.changeColor(stateName, color)}
                    onCancel={() => this.hideAlert()}
                />
            ),
        });
    };

    render() {
        const { classes, t, data2 } = this.props;
        return (
            <GridContainer justifyContent="space-between">
                {this.state.alert}

                <GridItem xs={12} sm={4}>
                    <PictureUpload
                        imgHolder={this.state.logo ? this.state.logo : defaultImage}
                        onUpload={(data) => this.change("logo", data)}
                        msg={t("group.choose_logo")}
                    />
                </GridItem>

                <GridItem xs={12} sm={8}>
                    <GridContainer justifyContent="space-between" container>
                        <GridItem xs={12} sm={12}>
                            <CustomInput
                                success={this.state.nameState === "success"}
                                error={this.state.nameState === "error"}
                                labelText={
                                    <span>
                                        {" "}
                                        {t("name")} <small> {t("required")} </small>{" "}
                                    </span>
                                }
                                id="name"
                                formControlProps={{ fullWidth: true }}
                                inputProps={{
                                    value: this.state.name,
                                    onChange: (event) =>
                                        this.change("name", event.target.value, "length", 3),
                                }}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={8}>
                            <CustomInput
                                labelText={<span> {t("group.display_name")} </span>}
                                id="display_name"
                                formControlProps={{ fullWidth: true }}
                                inputProps={{
                                    value: this.state.display_name,
                                    onChange: (event) =>
                                        this.change("display_name", event.target.value),
                                }}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={4}>
                            <CustomInput
                                labelText={<span> {t("kennitala")} </span>}
                                id="kennitala"
                                formControlProps={{ fullWidth: true }}
                                inputProps={{
                                    value: this.state.kennitala,
                                    onChange: (event) =>
                                        this.change("kennitala", event.target.value),
                                }}
                            />
                        </GridItem>
                    </GridContainer>
                </GridItem>

                <GridItem xs={12} sm={3}>
                    <FormControl
                        fullWidth
                        className={classes.selectFormControl}
                        style={{ marginTop: "10px" }}
                        variant="standard"
                    >
                        <InputLabel htmlFor="hidden_select" className={classes.selectLabel}>
                            {" "}
                            {t("group.hidden")}{" "}
                        </InputLabel>
                        <Select
                            MenuProps={{ className: classes.selectMenu, style: { zIndex: 35001 } }}
                            classes={{ select: classes.select }}
                            value={this.state.hidden}
                            onChange={(e) => this.change("hidden", e.target.value)}
                            inputProps={{ name: "hidden", id: "hidden_select" }}
                            disabled={this.state.type && this.state.type === "lite"}
                        >
                            <MenuItem classes={{ root: classes.selectMenuItem }} value={true}>
                                {" "}
                                {t("true")}{" "}
                            </MenuItem>
                            <MenuItem classes={{ root: classes.selectMenuItem }} value={false}>
                                {" "}
                                {t("false")}{" "}
                            </MenuItem>
                        </Select>
                    </FormControl>
                </GridItem>

                <GridItem xs={12} sm={3}>
                    <FormControl
                        fullWidth
                        className={classes.selectFormControl}
                        style={{ marginTop: "10px" }}
                        variant="standard"
                    >
                        <InputLabel htmlFor="card_type_select" className={classes.selectLabel}>
                            {" "}
                            {t("group.card_type")}{" "}
                        </InputLabel>
                        <Select
                            MenuProps={{ className: classes.selectMenu, style: { zIndex: 35001 } }}
                            classes={{ select: classes.select }}
                            value={this.state.has_membership_card}
                            onChange={(e) => this.change("has_membership_card", e.target.value)}
                            inputProps={{ name: "card_type", id: "card_type_select" }}
                            disabled={this.state.type && this.state.type === "lite"}
                        >
                            <MenuItem classes={{ root: classes.selectMenuItem }} value={false}>
                                {" "}
                                {t("group.use_default")}{" "}
                            </MenuItem>
                            <MenuItem classes={{ root: classes.selectMenuItem }} value={true}>
                                {" "}
                                {t("group.use_my_own")}{" "}
                            </MenuItem>
                        </Select>
                    </FormControl>
                </GridItem>

                <GridItem xs={12} sm={3}>
                    <FormControl fullWidth>
                        <InputLabel
                            htmlFor="font_colour"
                            shrink={true}
                            style={{ background: "white" }}
                        >
                            Font colour
                        </InputLabel>
                        <CustomInput
                            id="font_colour"
                            inputProps={{
                                placeholder: "#000000",
                                value: this.state.font_colour || "",
                                onChange: (event) => this.change("font_colour", event.target.value),
                                endAdornment: (
                                    <InputAdornment
                                        position="end"
                                        className={classes.colorPickerIcon}
                                        onClick={() => this.chooseColor("font_colour")}
                                    >
                                        <ColorLens />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </FormControl>
                </GridItem>

                <GridItem xs={12} sm={3}>
                    <FormControl
                        variant="standard"
                        fullWidth
                        className={classes.selectFormControl}
                        style={{ marginTop: "10px" }}
                    >
                        <InputLabel htmlFor="card_type_select" className={classes.selectLabel}>
                            {" "}
                            {"Group Type"}{" "}
                        </InputLabel>
                        <Select
                            MenuProps={{ className: classes.selectMenu, style: { zIndex: 35001 } }}
                            classes={{ select: classes.select }}
                            value={this.state.type}
                            onChange={(e) => this.changeGroupType(e.target.value)}
                            inputProps={{ name: "card_group_type", id: "card_group_type_select" }}
                        >
                            <MenuItem classes={{ root: classes.selectMenuItem }} value={"regular"}>
                                {" "}
                                Regular{" "}
                            </MenuItem>
                            <MenuItem classes={{ root: classes.selectMenuItem }} value={"lite"}>
                                {" "}
                                Lite{" "}
                            </MenuItem>
                            <MenuItem classes={{ root: classes.selectMenuItem }} value={"golden"}>
                                {" "}
                                Golden{" "}
                            </MenuItem>
                        </Select>
                    </FormControl>
                </GridItem>

                <GridItem xs={12} sm={12} />

                {this.state.type && this.state.type === "golden" && (
                    <React.Fragment>
                        <GridItem xs={12} sm={4}>
                            {Object.keys(data2).length > 0 && (
                                <FormControl
                                    variant="standard"
                                    fullWidth
                                    className={classes.selectFormControl}
                                    style={{ marginTop: "10px" }}
                                    error={this.state.groupState === "error"}
                                >
                                    <InputLabel
                                        variant="standard"
                                        htmlFor="simple-select"
                                        className={classes.selectLabel}
                                    >
                                        {" "}
                                        {t("special_companies")}{" "}
                                    </InputLabel>
                                    <Select
                                        MenuProps={{
                                            className: classes.selectMenu,
                                            style: { zIndex: 35001 },
                                        }}
                                        classes={{ select: classes.select }}
                                        value={""}
                                        onChange={(e) => this.handleChangeAttachGroup(e)}
                                        inputProps={{
                                            name: "attach_group_list",
                                            id: "attach_group_list_id",
                                        }}
                                    >
                                        {data2.results
                                            .filter(
                                                (item) =>
                                                    this.state.attached_group_ids.findIndex(
                                                        (f) => f.id === item.id
                                                    ) === -1
                                            )
                                            .map((value, index) => (
                                                <MenuItem
                                                    key={"attach_group_list_" + value.id}
                                                    classes={{ selected: classes.dSelected }}
                                                    value={value}
                                                >
                                                    {" "}
                                                    {value.name}{" "}
                                                </MenuItem>
                                            ))}
                                    </Select>
                                </FormControl>
                            )}
                        </GridItem>
                        <GridItem xs={12} sm={8} className={classes.attach_group_ids}>
                            {this.state.attached_group_ids &&
                                this.state.attached_group_ids.map((item, index) => (
                                    <div
                                        className={classes.tagItem}
                                        key={"attach_group_ids_items" + item.id + "_" + index}
                                    >
                                        {item.name}
                                        <button
                                            type="button"
                                            className={classes.button}
                                            onClick={() => this.handleDeleteAttachedGroup(item)}
                                        >
                                            &times;
                                        </button>
                                    </div>
                                ))}
                        </GridItem>
                    </React.Fragment>
                )}

                {this.state.has_membership_card && (
                    <GridItem xs={12} sm={12}>
                        <GridContainer justifyContent="space-between" container>
                            <GridItem xs={12} sm={12}>
                                <FormControl
                                    fullWidth
                                    className={classes.selectFormControl}
                                    style={{ marginTop: "10px" }}
                                    variant="standard"
                                >
                                    <InputLabel
                                        htmlFor="background_select"
                                        className={classes.selectLabel}
                                    >
                                        {" "}
                                        {t("group.choose_background")}{" "}
                                    </InputLabel>
                                    <Select
                                        MenuProps={{
                                            className: classes.selectMenu,
                                            style: { zIndex: 35001 },
                                        }}
                                        classes={{ select: classes.select }}
                                        value={this.state.background}
                                        onChange={(e) => this.change("background", e.target.value)}
                                        inputProps={{ name: "background", id: "background_select" }}
                                    >
                                        <MenuItem
                                            classes={{ root: classes.selectMenuItem }}
                                            value={"image"}
                                        >
                                            {" "}
                                            {t("group.choose_background")}{" "}
                                        </MenuItem>
                                        <MenuItem
                                            classes={{ root: classes.selectMenuItem }}
                                            value={"color"}
                                        >
                                            {" "}
                                            {t("group.choose_colour")}{" "}
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </GridItem>

                            {this.state.background === "image" && (
                                <GridItem xs={12} sm={12}>
                                    <PictureUpload
                                        imgHolder={
                                            this.state.card_image
                                                ? this.state.card_image
                                                : defaultImage
                                        }
                                        onUpload={(data) => this.change("card_image", data)}
                                        msg={t("group.upload_background")}
                                    />
                                </GridItem>
                            )}

                            {this.state.background === "color" && (
                                <GridContainer
                                    justifyContent="space-around"
                                    container
                                    style={{ height: "100px" }}
                                >
                                    <GridItem xs={12} sm={5}>
                                        <FormControl
                                            fullWidth
                                            className={classes.selectFormControl}
                                            style={{ marginTop: "10px" }}
                                            variant="standard"
                                        >
                                            <InputLabel
                                                htmlFor="position_select"
                                                className={classes.selectLabel}
                                            >
                                                {" "}
                                                {t("group.choose_logo_position")}{" "}
                                            </InputLabel>
                                            <Select
                                                MenuProps={{
                                                    className: classes.selectMenu,
                                                    style: { zIndex: 35001 },
                                                }}
                                                classes={{ select: classes.select }}
                                                value={this.state.card_logo_location}
                                                onChange={(e) =>
                                                    this.change(
                                                        "card_logo_location",
                                                        e.target.value
                                                    )
                                                }
                                                inputProps={{
                                                    name: "position",
                                                    id: "position_select",
                                                }}
                                            >
                                                <MenuItem
                                                    classes={{ root: classes.selectMenuItem }}
                                                    value={"top-right"}
                                                >
                                                    {" "}
                                                    top-right{" "}
                                                </MenuItem>
                                                <MenuItem
                                                    classes={{ root: classes.selectMenuItem }}
                                                    value={"top-left"}
                                                >
                                                    {" "}
                                                    top-left{" "}
                                                </MenuItem>
                                                <MenuItem
                                                    classes={{ root: classes.selectMenuItem }}
                                                    value={"bottom-right"}
                                                >
                                                    {" "}
                                                    bottom-right{" "}
                                                </MenuItem>
                                                <MenuItem
                                                    classes={{ root: classes.selectMenuItem }}
                                                    value={"bottom-left"}
                                                >
                                                    {" "}
                                                    bottom-left{" "}
                                                </MenuItem>
                                            </Select>
                                        </FormControl>
                                    </GridItem>

                                    <GridItem xs={12} sm={5}>
                                        <FormControl
                                            fullWidth
                                            className={classes.selectFormControl}
                                            variant="standard"
                                        >
                                            <InputLabel
                                                htmlFor="card_colour"
                                                className={classes.selectLabel}
                                                style={{ fontSize: "12px", top: "-10px" }}
                                            >
                                                {" "}
                                                {t("group.choose_colour")}{" "}
                                            </InputLabel>
                                            <CustomInput
                                                id="card_colour"
                                                formControlProps={{ fullWidth: true }}
                                                inputProps={{
                                                    placeholder: "#000000",
                                                    value: this.state.card_colour || "",
                                                    onChange: (event) =>
                                                        this.change(
                                                            "card_colour",
                                                            event.target.value
                                                        ),
                                                    endAdornment: (
                                                        <InputAdornment
                                                            position="end"
                                                            className={classes.colorPickerIcon}
                                                            onClick={() =>
                                                                this.chooseColor("card_colour")
                                                            }
                                                        >
                                                            <ColorLens />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </FormControl>
                                    </GridItem>
                                </GridContainer>
                            )}
                        </GridContainer>
                    </GridItem>
                )}
            </GridContainer>
        );
    }
}

export default withTranslation("", { withRef: true })(Step1);
