import { Permissions } from "config";

import Dashboard from "../views/dashboards/dashboard";
import AppUser from "../views/users/AppUsers";
import AddUser from "../views/users/AddUser";
import UserProfile from "../views/users/UserProfile";

import CompanyUser from "../views/company_users/CompanyUsers";
import AddCompanyUser from "../views/company_users/AddCompanyUser";
// import CompanyUserProfile from "../views/company_users/UserProfile";

import Groups from "../views/groups";
import AddGroup from "../views/groups/addGroup";

import Offers from "../views/offers/offers";
import AddOffer from "../views/offers/addOffer";
import OfferCategories from "../views/offer_category/category";

import Companies from "../views/companies/companies";
import AddCompany from "../views/companies/addCompany";
import CompanyCategories from "../views/company_category/category";

import Acl from "../views/acl/users";
import PermissionsView from "../views/acl/permissions";
import RolesView from "../views/acl/roles";

import SpecialUsers from "../views/special_users/SpecialUsers";
import Logs from "../views/activity_logs/logs";
import ActivityLogins from "../views/activity_logins/activityLogins";
import Reports from "../views/reports";

/////////// Company Users /////////////////
import CUProfile from "../views/cu_components/profile/userProfile";
import CUEditProfile from "../views/cu_components/profile/editProfile";
import CUCompanyUsers from "../views/cu_components/company_users/сompanyUsers";
import CUAddCompanyUser from "../views/cu_components/company_users/AddCompanyUser";
import CUCompany from "../views/cu_components/companies/company";
import CUEditCompany from "../views/cu_components/companies/editCompany";
import CUOffers from "../views/cu_components/offers/myOffers";
import CUAddOffer from "../views/cu_components/offers/addOffer";
import CUManagers from "../views/cu_components/managers/Managers";
import CUManagerProfile from "../views/cu_components/managers/ManagerProfile";
import CUDashboards from "../views/cu_components/dashboards/dashboard";
import CUEmployees from "../views/cu_components/employees/Employees";
import CUB2B from "../views/cu_components/b2b";
import CUAGroup from "../views/cu_components/groups";
import CUEditGroup from "../views/cu_components/groups/addGroup";
import CUAttachedGroups from "../views/cu_components/attached_groups";

import CUCustomMembers from "../views/cu_components/custom_members/Employees";

export default [
    {
        component: Dashboard,
        path: "/",
        title: "Dashboard",
        exact: true,
        permission: [Permissions.DASHBOARD_VIEW],
    },
    {
        component: Dashboard,
        path: "/dashboard",
        title: "Dashboard",
        exact: true,
        permission: [Permissions.DASHBOARD_VIEW],
    },
    {
        component: AppUser,
        path: "/app-user",
        title: "App User",
        permission: [Permissions.APP_USER_VIEW],
    },
    {
        component: AddUser,
        path: "/add-app-user",
        title: "Add User",
        permission: [Permissions.APP_USER_CREATE, Permissions.APP_USER_UPDATE],
    },
    {
        component: UserProfile,
        path: "/user-profile",
        title: "User Profile",
        permission: [Permissions.APP_USER_DETAILS],
    },
    {
        component: CompanyUser,
        path: "/company-user",
        title: "Company User",
        permission: [Permissions.COMPANY_USER_VIEW],
    },
    {
        component: AddCompanyUser,
        path: "/add-company-user",
        title: "Add Company User",
        permission: [Permissions.COMPANY_CREATE, Permissions.COMPANY_UPDATE],
    },
    // {
    // 	component: CompanyUserProfile,
    // 	path: '/profile-company-user',
    // 	title: 'Profile Company User',
    // 	permission: [
    // 		Permissions.COMPANY_USER_DETAILS
    // 	],
    // },
    {
        component: Groups,
        path: "/groups",
        title: "List of Groups",
        permission: [Permissions.GROUP_VIEW],
    },
    {
        component: AddGroup,
        path: "/add-group",
        title: "Add Group",
        permission: [Permissions.GROUP_CREATE, Permissions.GROUP_UPDATE],
    },
    {
        component: SpecialUsers,
        path: "/special-users",
        title: "Special Users",
        permission: [Permissions.SPECIAL_USER_VIEW],
    },
    {
        component: Offers,
        path: "/offers",
        title: "Offers",
        permission: [Permissions.OFFER_VIEW],
    },
    {
        component: AddOffer,
        path: "/add-offer",
        title: "Add Offers",
        permission: [Permissions.OFFER_CREATE, Permissions.OFFER_UPDATE],
    },
    {
        component: OfferCategories,
        path: "/offer-category",
        title: "Offer Categories",
        permission: [Permissions.CATEGORY_VIEW],
    },
    {
        component: Companies,
        path: "/companies",
        title: "Companies",
        permission: [Permissions.COMPANY_VIEW],
    },
    {
        component: AddCompany,
        path: "/add-company",
        title: "Add Company",
        permission: [Permissions.COMPANY_CREATE, Permissions.COMPANY_UPDATE],
    },

    {
        component: AddCompany,
        path: "/company/:id",
        title: "Company preview",
        permission: [Permissions.COMPANY_CREATE, Permissions.COMPANY_UPDATE],
    },
    {
        component: CompanyCategories,
        path: "/company-category",
        title: "Company Categories",
        permission: [Permissions.COMPANY_VIEW],
    },
    {
        component: Logs,
        path: "/logs",
        title: "Logs",
        permission: [Permissions.LOG_VIEW],
    },
    {
        component: ActivityLogins,
        path: "/logins",
        title: "logins",
        permission: [Permissions.ACTIVITY_LOGIN_VIEW],
    },
    {
        component: Reports,
        path: "/reports",
        title: "Reports",
        permission: [Permissions.ACTIVITY_LOGIN_VIEW],
    },
    {
        component: Acl,
        path: "/acl",
        title: "User Management",
        permission: [Permissions.ACL_VIEW],
    },
    {
        component: PermissionsView,
        path: "/permissions",
        title: "Permissions",
        permission: [Permissions.PERMISSION_VIEW],
    },
    {
        component: RolesView,
        path: "/roles",
        title: "Roles",
        permission: [Permissions.ROLE_VIEW],
    },

    //////////////////////////////////////////////////
    //////////// Company users ///////////////////////

    {
        component: CUDashboards,
        path: "/",
        title: "Dashboard",
        exact: true,
        permission: [Permissions.CU_DASHBOARD_VIEW],
    },
    {
        component: CUDashboards,
        path: "/dashboard",
        title: "Dashboard",
        exact: true,
        permission: [Permissions.CU_DASHBOARD_VIEW],
    },
    {
        component: CUEditProfile,
        path: "/edit-profile",
        title: "Edit Profile",
        permission: [Permissions.CU_PROFILE_UPDATE],
    },
    // {
    // 	component: CUProfile,
    // 	path: '/profile-cu',
    // 	title: 'Profile Company User',
    // 	permission: [
    // 		Permissions.CU_PROFILE_VIEW
    // 	],
    // },
    {
        component: CUCompany,
        path: "/companies",
        title: "My companies",
        exact: true,
        permission: [Permissions.CU_COMPANY_VIEW],
    },
    {
        component: CUCompanyUsers,
        path: "/company-user",
        title: "Company Users",
        permission: [Permissions.CU_COMPANY_USER_VIEW],
    },
    {
        component: CUAddCompanyUser,
        path: "/add-new-company-user",
        title: "Add Company User",
        permission: [Permissions.CU_COMPANY_USER_CREATE, Permissions.CU_COMPANY_USER_UPDATE],
    },
    {
        component: CUEditCompany,
        path: "/edit-company",
        title: "Edit company",
        exact: true,
        permission: [Permissions.CU_COMPANY_UPDATE],
    },
    {
        component: CUOffers,
        path: "/my-offers",
        title: "My offers",
        exact: true,
        permission: [Permissions.CU_OFFER_VIEW],
    },
    {
        component: CUAddOffer,
        path: "/add-my-offer",
        title: "Add Offers",
        permission: [Permissions.CU_OFFER_CREATE, Permissions.CU_OFFER_UPDATE],
    },
    {
        component: CUEmployees,
        path: "/members",
        title: "My employees",
        exact: true,
        permission: [Permissions.CU_EMPLOYEE_VIEW],
    },
    {
        component: CUManagers,
        path: "/managers",
        title: "Managers",
        exact: true,
        permission: [Permissions.CU_MANAGER_VIEW],
        showByGroupType: ["regular", "golden"],
    },

    {
        component: CUCustomMembers,
        path: "/my_members",
        title: "My employees",
        exact: true,
        permission: [Permissions.CU_CUSTOM_EMPLOYEE_VIEW],
    },

    {
        component: CUManagerProfile,
        path: "/profile-managers",
        title: "Profile Managers",
        exact: true,
        permission: [Permissions.CU_MANAGER_DETAILS],
    },
    {
        component: CUB2B,
        path: "/b2b",
        title: "B2B offers",
        exact: true,
        permission: [Permissions.CU_B2B_VIEW],
    },
    {
        component: CUAGroup,
        path: "/my-group",
        title: "My Group",
        permission: [Permissions.CU_GROUP_UPDATE],
    },
    {
        component: CUAttachedGroups,
        path: "/attached-group",
        title: "Attached Group",
        exact: true,
        permission: [Permissions.CU_GROUP_UPDATE],
        showByGroupType: ["golden"],
    },

    {
        component: CUEditGroup,
        path: "/edit-group",
        title: "Edit Group",
        permission: [Permissions.CU_GROUP_UPDATE],
    },

    // {    Example
    // 	component: ModuleN,
    // 	path: '/module-n',
    // 	title: 'Module - N',
    // 	permission: [
    // 		Roles.SUPER_ADMIN,
    // 		Roles.ADMIN,
    // 		Roles.MANAGER
    // 	],
    // 	children: [
    // 		{
    // 			component: ModuleNChild1,
    // 			path: '/child-1',
    // 			title: 'Child - 1',
    // 		},
    // 		{
    // 			component: ModuleNChild3,
    // 			path: '/child-3',
    // 			title: 'Child - 3',
    // 			permission: [
    // 				Roles.SUPER_ADMIN,
    // 				Roles.ADMIN
    // 			]
    // 		}
    // 	]
    // },
];
