import React from "react";
import {withTranslation} from 'react-i18next';
import axios from "axios";

// @material-ui
import {Facebook, Twitter, Instagram, YouTube, Pinterest, LinkedIn, Close, ColorLens} from '@mui/icons-material';
import {FormControl, InputLabel, Select, MenuItem, InputAdornment, Input, TextareaAutosize, TextField} from "@mui/material";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import PictureUpload from "components/CustomUpload/PictureUpload.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import SweetAlert from "react-bootstrap-sweetalert";
import Autocomplete from "@mui/material/Autocomplete";
import ColorPicker from "../../../components/ColorPicker/ColorPicker";

class Step1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      alert: '',
      disabled: !props.company.id,
      id: props.company.id || 0,
      name: props.company.name || "",
      nameState: props.company.name ? "success" : "",
      kennitala: props.company.kennitala || "",
      kennitalaState: props.company.kennitala ? "success" : "",
      phone: this.renderContact(props.company.torgid_company_contacts, props.company.phone, 'phone'),
      phoneValue:  "",
      phoneState: "",
      email: this.renderContact(props.company.torgid_company_contacts, props.company.email, 'email'),
      emailValue: "",
      emailState: "",
      website: this.renderContact(props.company.torgid_company_contacts, props.company.website, 'website'),
      websiteValue: "",
      websiteState: "",
      torgid_company_tags: [],
      // torgid_company_awards: [],
      tagsService: props.company.torgid_company_tags ? props.company.torgid_company_tags.filter(val => val.type === 'service').map(t => t.value) : [],
      tagsServiceValue: "",
      tagsServiceState: "",
      tagsBrand: props.company.torgid_company_tags ? props.company.torgid_company_tags.filter(val => val.type === 'brand').map(t => t.value) : [],
      tagsBrandValue: "",
      tagsBrandState: "",
      // awards: props.company.torgid_company_awards ? props.company.torgid_company_awards.map(val => `${val.type}:${val.value}`) : [],
      // awardsValue: "",
      // awardsState: "",
      description: props.company.description || "",
      description_en: props.company.description_en || "",
      offer_limit: props.company.offer_limit || 5,
      type: props.company.type ? props.company.type : 'regular',
      logo: '',
      logoPreview: props.company.logo || '',
      cover_photo: '',
      cover_photo_preview: props.company.cover_photo || '',
      facebook: props.company.torgid_company_social ? props.company.torgid_company_social.facebook : null,
      facebookState: props.company.torgid_company_social ? !!props.company.torgid_company_social.facebook : false,
      twitter: props.company.torgid_company_social ? props.company.torgid_company_social.twitter : null,
      twitterState: props.company.torgid_company_social ? !!props.company.torgid_company_social.twitter : false,
      instagram: props.company.torgid_company_social ? props.company.torgid_company_social.instagram : null,
      instagramState: props.company.torgid_company_social ? !!props.company.torgid_company_social.instagram : false,
      youtube: props.company.torgid_company_social ? props.company.torgid_company_social.youtube : null,
      youtubeState: props.company.torgid_company_social ? !!props.company.torgid_company_social.youtube : false,
      pinterest: props.company.torgid_company_social ? props.company.torgid_company_social.pinterest : null,
      pinterestState: props.company.torgid_company_social ? !!props.company.torgid_company_social.pinterest : false,
      linkedin: props.company.torgid_company_social ? props.company.torgid_company_social.linkedin : null,
      linkedinState: props.company.torgid_company_social ? !!props.company.torgid_company_social.linkedin : false,
      tint_colour: props.company.tint_colour || '#4c535a',
      torgid_company_category_id: props.company.torgid_company_category_id || null,
      torgid_company_categories: props.company.torgid_company_category_nxns ? props.company.torgid_company_category_nxns.map(data => data.torgid_company_category_id) : [],
      imgUpload:this.imgUpload.bind(this)
    };
  }

  static getDerivedStateFromProps(props, state) {
    if(!props.company) return null;
    if(props.company.company_from === 'Torgid') {
      if (props.company.id && props.company.id !== state.id) {
        return {
          id: props.company.id || 0,
          name: props.company.name || "",
          nameState: props.company.name ? "success" : "",
          kennitala: props.company.kennitala || "",
          kennitalaState: props.company.kennitala ? "success" : "",
          phone: props.company.torgid_company_contacts ? props.company.torgid_company_contacts.filter(val => val.type === 'phone').map(t => t.value) : [],
          email: props.company.torgid_company_contacts ? props.company.torgid_company_contacts.filter(val => val.type === 'email').map(t => t.value) : [],
          website: props.company.torgid_company_contacts ? props.company.torgid_company_contacts.filter(val => val.type === 'website').map(t => t.value) : [],
          tagsService: props.company.torgid_company_tags ? props.company.torgid_company_tags.filter(val => val.type === 'service').map(t => t.value) : [],
          tagsBrand: props.company.torgid_company_tags ? props.company.torgid_company_tags.filter(val => val.type === 'brand').map(t => t.value) : [],
          // awards: props.company.torgid_company_awards ? props.company.torgid_company_awards.map(val => `${val.type}:${val.value}`) : [],
          description: props.company.description || "",
          description_en: props.company.description_en || "",
          offer_limit: props.company.offer_limit || 5,
          type: props.company.type ? props.company.type : 'regular',
          logo: '',
          logoPreview: props.company.logo || '',
          cover_photo: '',
          cover_photo_preview: props.company.cover_photo || '',
          facebook: props.company.torgid_company_social ? props.company.torgid_company_social.facebook : null,
          facebookState: props.company.torgid_company_social ? !!props.company.torgid_company_social.facebook : false,
          twitter: props.company.torgid_company_social ? props.company.torgid_company_social.twitter : null,
          twitterState: props.company.torgid_company_social ? !!props.company.torgid_company_social.twitter : false,
          instagram: props.company.torgid_company_social ? props.company.torgid_company_social.instagram : null,
          instagramState: props.company.torgid_company_social ? !!props.company.torgid_company_social.instagram : false,
          youtube: props.company.torgid_company_social ? props.company.torgid_company_social.youtube : null,
          youtubeState: props.company.torgid_company_social ? !!props.company.torgid_company_social.youtube : false,
          pinterest: props.company.torgid_company_social ? props.company.torgid_company_social.pinterest : null,
          pinterestState: props.company.torgid_company_social ? !!props.company.torgid_company_social.pinterest : false,
          linkedin: props.company.torgid_company_social ? props.company.torgid_company_social.linkedin : null,
          linkedinState: props.company.torgid_company_social ? !!props.company.torgid_company_social.linkedin : false,
          tint_colour: props.company.tint_colour || '#4c535a',
          torgid_company_category_id: props.company.torgid_company_category_id || null,
        };
      }
    } else if(props.company.company_from === '1819') {
      if (props.company.name !== state.name) {
        return {
          id: 0,
          name: props.company.name || "",
          nameState: props.company.name ? "success" : "",
          phone: props.company.phones || [],
          email: props.company.email || [],
          website: props.company.website || [],
          logo: props.company.logo && props.company.logo.length>0 ? state.imgUpload(props.company.logo, 'logo') : '',
          logoPreview: props.company.logo || '',
          cover_photo: props.company.head && props.company.head.length>0 ? state.imgUpload(props.company.head, 'cover_photo') : '',
          cover_photo_preview: props.company.head || '',
          tint_colour: props.company.background_color || '#4c535a',

          facebook: props.company.facebook || null,
          facebookState: !!props.company.facebook,
          twitter: props.company.twitter || null,
          twitterState: !!props.company.twitter,
          instagram: props.company.instagram || null,
          instagramState: !!props.company.instagram,
          tagsService: props.company.services ? props.company.services.split(' ') : [],
          tagsBrand: props.company.brands ? props.company.brands.split(' ') : [],

        };
      }
    }
    return null;
  }

  imgUpload = async (url, state) => {
    var thisS = this;
    const res = await fetch(url);
    const imageBlob = await res.blob();

    var reader = new FileReader();
    reader.readAsDataURL(imageBlob);
    reader.onload = function(e){
      e.target.result.length>0 && thisS.setState({[state]: e.target.result});
    };

  };

  // imgUpload = (url, state) => {
  //   var request = new XMLHttpRequest();
  //   var thisS = this;
  //   request.open('GET', url, true);
  //   request.responseType = 'blob';
  //   request.onload = function() {
  //     var reader = new FileReader();
  //     reader.readAsDataURL(request.response);
  //     reader.onload = function(e){
  //       e.target.result.length>0 && thisS.setState({[state]: e.target.result});
  //     };
  //   };
  //   request.send();
  // };

  renderContact = (data, data2, type) => {
    let temp = [];
    if(data && data.length >0) {
      data.map((value, index) => {
        if(value.type === type) {
          temp.push(value.value);
        }
      });
    } else if (data && data.length === 0 && data2) {
      temp.push(data2);
    }

    return temp;
  };


  handleKeyDown = (evt, type) => {
    if (["Enter", "Tab", ","].includes(evt.key)) {
      evt.preventDefault();
      var value = this.state[type+'Value'].trim();
      if (value && this.isValid(value, type)) {
        this.setState({
          [type]: [...this.state[type], this.state[type+'Value']],
          [type+'Value']: ""
        });
      }
    }
  };

  handleChangeContact = (evt, type) => {
    this.setState({
      [type+'Value']: evt.target.value,
      [type+'State']: null
    });
  };

  handleDelete = (item, type) => {
    this.setState({
      [type]: this.state[type].filter(i => i !== item)
    });
  };

  isValid(value, type) {
    let error = null;

    if (this.state[type].includes(value)) {
      error = `this has already been added.`;
    }

    if(type === 'email') {
      if (!this.verifyEmail(value)) {
        error = `this not a valid email.`;
      }
    } else if (type === 'phone') {
      if (!/^\d{7}$/.test(value)) {
        error = `this not a valid phone.`;
      }
    } else if (type === 'website') {
      if (!/[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi.test(value)) {
        error = `this not a valid website.`;
      }
    } else if (type === 'awards') {
      if (!/^\w{3,}:\w{3,}$/.test(value)) {
        error = `this not a valid award.`;
      }
    }

    if (error) {
      this.setState({ [type+'State']: error });
      return false;
    }

    return true;
  }

  sendState() {
    return this.state;
  }

  verifyLength(value, length) {
    return value.length >= length;
  }

  verifyEmail(value) {
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailRex.test(value);
  }

  handleChange = (data, type) => {
    this.setState({ [type]: data });
  };

  onUpload(data, type) {
    this.setState({  [type]: data });
  }

  hideAlert = () => {
    this.setState({alert: null})
  };

  handleChangeCategory = (data) => {
    if(data) {
      let ids = data.map(data => data.id);
      this.setState({ torgid_company_categories: ids });
    }
  };

  handleCheckKennitala = () => {
    this.props.checkCompany(this.state.kennitala);
    this.setState({disabled: false});
  };

  autoTranslate = (type, fromLn, toLn) => {
    let textOld = this.state.description;
    let text = textOld.replace(/&/g, '');
    axios.get(`https://translation.googleapis.com/language/translate/v2?key=AIzaSyA5AF_VTEbn4TXQ2uMWsaEx0Al53mT7aOU&q=${text}&source=${fromLn}&target=${toLn}`)
      .then((response) => {
        if(response.status === 200) {
          let translatedText = response.data.data.translations[0].translatedText;
          this.setState({  [type]: translatedText.replace(new RegExp('&#39;', 'g'), "'") });
        } else {
         this.setState({alert: <SweetAlert title={response.statusText} onConfirm={() => this.hideAlert()} showConfirm={false} closeOnClickOutside={true}/>});
          setTimeout(this.hideAlert, 2000);
        }
      });
  };

  change(event, stateName, type, stateNameEqualTo) {
    if (type === "length") {
      if (this.verifyLength(event.target.value, stateNameEqualTo)) {
        this.setState({ [stateName + "State"]: "success" });
      } else {
        this.setState({ [stateName + "State"]: "error" });
      }
    }
    if (type === "lengthEqual") {
      if (event.target.value.length === stateNameEqualTo) {
        this.setState({ [stateName + "State"]: "success" });
      } else {
        this.setState({ [stateName + "State"]: "error" });
      }
    }
    if (type === "email") {
      if (this.verifyEmail(event.target.value, stateNameEqualTo)) {
        this.setState({ [stateName + "State"]: "success" });
      } else {
        this.setState({ [stateName + "State"]: "error" });
      }
    }
    if (type === "phone") {
      if (/^\d{7}$/.test(event.target.value)) {
        this.setState({ [stateName + "State"]: "success" });
      } else {
        this.setState({ [stateName + "State"]: "error" });
      }
    }
    this.setState({ [stateName]: event.target.value });
  }

  isValidated() {
    if (
      this.state.nameState === "success" &&
      this.state.kennitalaState === "success"
      // this.state.phone.length > 0 &&
      // this.state.email.length > 0 &&
      // this.state.website.length > 0
    ) {
      return true;
    } else {
      if (this.state.nameState !== "success") {
        this.setState({ nameState: "error" });
      }
      if (this.state.kennitalaState !== "success") {
        this.setState({ kennitalaState: "error" });
      }
      // if (this.state.phone.length <= 0) {
      //   this.setState({ phoneState: "Vinsamlega fylltu reitinn út" });
      // }
      // if (this.state.email.length <= 0) {
      //   this.setState({ emailState: "Vinsamlega fylltu reitinn út" });
      // }
      // if (this.state.website.length <= 0) {
      //   this.setState({ websiteState: "Vinsamlega fylltu reitinn út" });
      // }
    }
    return false;
  }

  changeColor = (stateName, color) => {
    this.setState({ [stateName]: color });
    this.hideAlert()
  };

  chooseColor = (stateName) => {
    this.setState({
      alert:
        <ColorPicker confirm={(color) => this.changeColor(stateName, color)} onCancel={() => this.hideAlert()}/>
    });
  };

  render() {
    const {classes, company, t, data2 } = this.props;
    const options = data2 && data2.map((option) => {
      const firstLetter = option.title.charAt(0).toUpperCase();
      return {
        firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
        ...option,
      };
    });

    return (
      <GridContainer justifyContent="space-between">
        {this.state.alert}
        <GridItem xs={12} sm={2}>
          <PictureUpload imgHolder={this.state.logoPreview} msg={t('choose_logo')}  onUpload={(data) => this.onUpload(data, "logo")} />
        </GridItem>
        <GridItem xs={12} sm={2}>
          <PictureUpload imgHolder={this.state.cover_photo_preview} msg={t('cover_photo')}  onUpload={(data) => this.onUpload(data, "cover_photo")} />
        </GridItem>
        <GridItem xs={12} sm={7}>
          <CustomInput
            success={this.state.kennitalaState === "success"}
            error={this.state.kennitalaState === "error"}
            labelText={ <span> {t('kennitala')} <small>{t('required')}</small> </span> }
            id="kennitala"
            formControlProps={{ fullWidth: true }}
            inputProps={{
              value: this.state.kennitala,
              type: 'number',
              onChange: event => this.change(event, "kennitala", "lengthEqual", 10),
            }}
          />
          <CustomInput
            success={this.state.nameState === "success"}
            error={this.state.nameState === "error"}
            labelText={ <span> {t('name')} <small>{t('required')}</small> </span> }
            id="Name"
            formControlProps={{ fullWidth: true }}
            inputProps={{
              value: this.state.name,
              onChange: event => this.change(event, "name", "length", 2),
            }}
          />
        </GridItem>

        <GridItem xs={12} sm={12}> <hr className={classes.line}/> </GridItem>
        <GridItem xs={12} sm={12} style={{textAlign: 'center', fontWeight: 'normal', color: '#00acc1'}}> {t('email')}</GridItem>
        <GridItem xs={12} sm={12}>
          {this.state.email.map(item => (
            <div className={classes.tagItem} key={item}>
              {item}
              <button type="button" className={classes.button} onClick={() => this.handleDelete(item, 'email')}>
                &times;
              </button>
            </div>
          ))}
          <CustomInput
            success={this.state.emailState === "success"}
            error={!!this.state.emailState}
            labelText={ this.state.emailState ? this.state.emailState :  t('company.type_email') }
            id="email"
            inputProps={{
              style: {width: '200px'},
              value: this.state.emailValue,
              onChange: event => this.handleChangeContact(event, 'email'),
              onKeyDown: event => this.handleKeyDown(event, 'email')
            }}
          />
        </GridItem>

        <GridItem xs={12} sm={12}> <hr className={classes.line}/> </GridItem>
        <GridItem xs={12} sm={12} style={{textAlign: 'center', fontWeight: 'normal', color: '#00acc1'}}> {t('phone')} </GridItem>
        <GridItem xs={12} sm={12}>
          {this.state.phone.map(item => (
            <div className={classes.tagItem} key={item}>
              {item}
              <button type="button" className={classes.button} onClick={() => this.handleDelete(item, 'phone')}>
                &times;
              </button>
            </div>
          ))}
          <CustomInput
            success={this.state.phoneState === "success"}
            error={!!this.state.phoneState}
            labelText={ this.state.phoneState ? this.state.phoneState : t('company.type_phone') }
            id="phone"
            inputProps={{
              style: {width: '200px'},
              value: this.state.phoneValue,
              onChange: event => this.handleChangeContact(event, 'phone'),
              onKeyDown: event => this.handleKeyDown(event, 'phone')
            }}
          />
        </GridItem>

        <GridItem xs={12} sm={12}> <hr className={classes.line}/> </GridItem>
        <GridItem xs={12} sm={12} style={{textAlign: 'center', fontWeight: 'normal', color: '#00acc1'}}> {t('website')} </GridItem>
        <GridItem xs={12} sm={12}>
          {this.state.website.map(item => (
            <div className={classes.tagItem} key={item}>
              {item}
              <button type="button" className={classes.button} onClick={() => this.handleDelete(item, 'website')}>
                &times;
              </button>
            </div>
          ))}
          <CustomInput
            success={this.state.websiteState === "success"}
            error={!!this.state.websiteState}
            labelText={ this.state.websiteState ? this.state.websiteState :  t('company.type_website') }
            id="website"
            inputProps={{
              style: {width: '200px'},
              value: this.state.websiteValue,
              onChange: event => this.handleChangeContact(event, 'website'),
              onKeyDown: event => this.handleKeyDown(event, 'website')
            }}
          />
        </GridItem>

        <GridItem xs={12} sm={12}> <hr className={classes.line}/> </GridItem>
        <GridItem xs={12} sm={12} style={{textAlign: 'center', fontWeight: 'normal', color: '#00acc1'}}> {t('company.tags')} </GridItem>
        <GridItem xs={12} sm={12}>
          {this.state.tagsService.map(item => (
            <div className={classes.tagItem} key={item}>
              {item}
              <button type="button" className={classes.button} onClick={() => this.handleDelete(item, 'tagsService')}>&times;</button>
            </div>
          ))}
          <CustomInput
            success={this.state.tagsServiceState === "success"}
            error={!!this.state.tagsServiceState}
            labelText={ this.state.tagsServiceState ? this.state.tagsServiceState : t('company.type_service') }
            id="tags"
            inputProps={{
              style: {width: '250px'},
              value: this.state.tagsServiceValue,
              onChange: event => this.handleChangeContact(event, 'tagsService'),
              onKeyDown: event => this.handleKeyDown(event, 'tagsService')
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12}>
          {this.state.tagsBrand.map(item => (
            <div className={classes.tagItem} key={item}>
              {item}
              <button type="button" className={classes.button} onClick={() => this.handleDelete(item, 'tagsBrand')}>&times;</button>
            </div>
          ))}
          <CustomInput
            success={this.state.tagsBrandState === "success"}
            error={!!this.state.tagsBrandState}
            labelText={ this.state.tagsBrandState ? this.state.tagsBrandState : t('company.type_brand') }
            id="brand"
            inputProps={{
              style: {width: '250px'},
              value: this.state.tagsBrandValue,
              onChange: event => this.handleChangeContact(event, 'tagsBrand'),
              onKeyDown: event => this.handleKeyDown(event, 'tagsBrand')
            }}
          />
        </GridItem>

        {/*<GridItem xs={12} sm={12}> <hr className={classes.line}/> </GridItem>*/}
        {/*<GridItem xs={12} sm={12} style={{textAlign: 'center', fontWeight: 'normal', color: '#00acc1'}}> {t('company.awards')} </GridItem>*/}
        {/*<GridItem xs={12} sm={12}>*/}
        {/*  {this.state.awards.map(item => (*/}
        {/*    <div className={classes.tagItem} key={item}>*/}
        {/*      {item}*/}
        {/*      <button type="button" className={classes.button} onClick={() => this.handleDelete(item, 'awards')}>&times;</button>*/}
        {/*    </div>*/}
        {/*  ))}*/}
        {/*  <CustomInput*/}
        {/*    success={this.state.awardsState === "success"}*/}
        {/*    error={!!this.state.awardsState}*/}
        {/*    labelText={ this.state.awardsState ? this.state.awardsState : t('company.award_example') }*/}
        {/*    id="awards"*/}
        {/*    inputProps={{*/}
        {/*      style: {width: '250px'},*/}
        {/*      value: this.state.awardsValue,*/}
        {/*      onChange: event => this.handleChangeContact(event, 'awards'),*/}
        {/*      onKeyDown: event => this.handleKeyDown(event, 'awards')*/}
        {/*    }}*/}
        {/*  />*/}
        {/*</GridItem>*/}

        <GridItem xs={12} sm={6} className={classes.paddingRow}>
          <TextareaAutosize
            className={classes.txtArea}
            minRows={4}
            maxRows={8}
            id="description"
            aria-label="description"
            placeholder={t('description')}
            value={this.state.description}
            onChange={event => this.change(event, "description", "length", 10)}
          />
        </GridItem>
        <GridItem xs={12} sm={6}  className={classes.paddingRow}>
          <TextareaAutosize
            className={classes.txtArea}
            minRows={4}
            maxRows={8}
            id="description_en"
            aria-label="description_en"
            placeholder={t('description_en')}
            value={this.state.description_en}
            onChange={event => this.change(event, "description_en")}
          />
          {!this.state.description ? <Button color="default" size="sm"> {t('please_fill_description')} </Button>
            : <Button color="success" size="sm" onClick={() => this.autoTranslate('description_en', 'is', 'en')}> {t('translate_is_en')} </Button>
          }
        </GridItem>

        <GridItem xs={12} sm={2}>
          <CustomInput
            labelText={ <span>{t('offer_limit')} </span> }
            id="offer_limit"
            formControlProps={{ fullWidth: true }}
            inputProps={{
              value: this.state.offer_limit,
              type: 'number',
              onChange: event => this.change(event, "offer_limit"),
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={3}>
          <FormControl fullWidth className={classes.selectFormControl} style={{marginTop: '10px'}} variant="standard">
            <InputLabel htmlFor="simple-select" className={classes.selectLabel}> {t('type')} </InputLabel>
            <Select
              MenuProps={{ className: classes.selectMenu }}
              classes={{ select: classes.select }}
              value={this.state.type}
              onChange={e => this.handleChange(e.target.value, 'type')}
              inputProps={{ name: "type", id: "type"}}
            >
              <MenuItem classes={{ selected: classes.dSelected}} value="regular"> {t('company.regular')} </MenuItem>
              <MenuItem classes={{ selected: classes.dSelected}} value="premium"> {t('company.premium')} </MenuItem>
            </Select>
          </FormControl>
        </GridItem>

        <GridItem xs={12} sm={3}>
          <FormControl fullWidth className={classes.selectFormControl}>
            <InputLabel htmlFor="tint_colour" className={classes.selectLabel} style={{fontSize: '12px', top: '-10px'}}> Tint colour </InputLabel>
            <CustomInput
              id="tint_colour"
              formControlProps={{ fullWidth: true }}
              inputProps={{
                placeholder: '#000000',
                value: this.state.tint_colour || '',
                onChange: event => this.change( event,"tint_colour"),
                endAdornment: (
                  <InputAdornment position="end" className={classes.colorPickerIcon} onClick={() => this.chooseColor("tint_colour")}>
                    <ColorLens />
                  </InputAdornment>
                )
              }}
            />
          </FormControl>
        </GridItem>

        <GridItem xs={12} sm={4}>
          <Autocomplete
            multiple
            id={"companies"}
            onChange={(event, newValue) => {
              this.handleChangeCategory(newValue)
            }}
            value={this.state.torgid_company_categories ? this.state.torgid_company_categories.map(data => options.find(item => item.id === data)) : [] }
            getOptionLabel={(option) => option?.title}
            getOptionSelected={(option, value) => option.id === value.id}
            options={options ? options.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter)) : []}
            groupBy={(option) => option.firstLetter}
            // renderOption={(props, option) => (
            //   <div><img key={option.id+'imgId'} loading="lazy" width="20" src={option.logo} style={{marginRight: '10px'}}/>{option.title} ({option.title_en})</div>
            // )}
            style={{ marginTop: 10 }}
            renderInput={(params) => <TextField {...params} variant="standard" label={`${t('choose_category')}`} />}
          />
        </GridItem>

        <GridItem xs={12} sm={12}> <hr style={{marginBottom: 0}}/> </GridItem>
        <GridItem xs={12} sm={12} style={{textAlign: 'center', fontWeight: 'normal', color: '#00acc1'}}> {t('social_links')} </GridItem>

        <GridItem xs={12} sm={12} className={classes.paddingRow}>
          <GridContainer justifyContent="center">
            <Facebook className={this.state.facebookState ? classes.socialA : classes.social } onClick={() => this.handleChange(true, 'facebookState')} />
            <Twitter className={this.state.twitterState ? classes.socialA : classes.social } onClick={() => this.handleChange(true, 'twitterState')} />
            <Instagram className={this.state.instagramState ? classes.socialA : classes.social } onClick={() => this.handleChange(true, 'instagramState')} />
            <YouTube className={this.state.youtubeState ? classes.socialA : classes.social } onClick={() => this.handleChange(true, 'youtubeState')} />
            <Pinterest className={this.state.pinterestState ? classes.socialA : classes.social } onClick={() => this.handleChange(true, 'pinterestState')} />
            <LinkedIn className={this.state.linkedinState ? classes.socialA : classes.social } onClick={() => this.handleChange(true, 'linkedinState')} />
          </GridContainer>
        </GridItem>

        {this.state.facebookState && <GridContainer justifyContent="center" className={classes.paddingRow}>
          <GridItem xs={12} sm={10}>
          <FormControl fullWidth >
            <Input id="input-facebook" className={classes.socialTxt}
              startAdornment={ <InputAdornment position="start"> <Facebook /> </InputAdornment> }
              value={this.state.facebook || "http://facebook.com/"}
              onChange={ event => this.handleChange(event.target.value,  "facebook") }
            />
          </FormControl>
          </GridItem>
          <GridItem xs={12} sm={2}>
            <Close onClick={() => this.handleChange(false, 'facebookState')} className={classes.socialD} />
          </GridItem>
        </GridContainer> }

        {this.state.twitterState && <GridContainer justifyContent="center" className={classes.paddingRow}>
          <GridItem xs={12} sm={10}>
            <FormControl fullWidth >
              <Input id="input-twitter" className={classes.socialTxt}
                startAdornment={ <InputAdornment position="start"> <Twitter /> </InputAdornment> }
                value={this.state.twitter || "http://twitter.com/"}
                onChange={ event => this.handleChange(event.target.value,  "twitter") }
              />
            </FormControl>
          </GridItem>
          <GridItem xs={12} sm={2}>
            <Close onClick={() => this.handleChange(false, 'twitterState')} className={classes.socialD} />
          </GridItem>
        </GridContainer> }

        {this.state.instagramState && <GridContainer justifyContent="center" className={classes.paddingRow}>
          <GridItem xs={12} sm={10}>
            <FormControl fullWidth >
              <Input id="input-instagram" className={classes.socialTxt}
                startAdornment={ <InputAdornment position="start"> <Instagram /> </InputAdornment> }
                value={this.state.instagram || "http://instagram.com/"}

                onChange={ event => this.handleChange(event.target.value,  "instagram") }
              />
            </FormControl>
          </GridItem>
          <GridItem xs={12} sm={2}>
            <Close onClick={() => this.handleChange(false, 'instagramState')} className={classes.socialD} />
          </GridItem>
        </GridContainer> }

        {this.state.youtubeState && <GridContainer justifyContent="center" className={classes.paddingRow}>
          <GridItem xs={12} sm={10}>
            <FormControl fullWidth >
              <Input id="input-youtube" className={classes.socialTxt}
                startAdornment={ <InputAdornment position="start"> <YouTube /> </InputAdornment> }
                value={this.state.youtube || "http://youtube.com/"}
                onChange={ event => this.handleChange(event.target.value,  "youtube") }
              />
            </FormControl>
          </GridItem>
          <GridItem xs={12} sm={2}>
            <Close onClick={() => this.handleChange(false, 'youtubeState')} className={classes.socialD} />
          </GridItem>
        </GridContainer> }

        {this.state.pinterestState && <GridContainer justifyContent="center" className={classes.paddingRow}>
          <GridItem xs={12} sm={10}>
            <FormControl fullWidth >
              <Input id="input-pinterest" className={classes.socialTxt}
                startAdornment={ <InputAdornment position="start"> <Pinterest /> </InputAdornment> }
                value={this.state.pinterest || "http://pinterest.com/"}
                onChange={ event => this.handleChange(event.target.value,  "pinterest") }
              />
            </FormControl>
          </GridItem>
          <GridItem xs={12} sm={2}>
            <Close onClick={() => this.handleChange(false, 'pinterestState')} className={classes.socialD} />
          </GridItem>
        </GridContainer> }

        {this.state.linkedinState && <GridContainer justifyContent="center" className={classes.paddingRow}>
          <GridItem xs={12} sm={10}>
            <FormControl fullWidth >
              <Input id="input-linkedin" className={classes.socialTxt}
                startAdornment={ <InputAdornment position="start"> <LinkedIn /> </InputAdornment> }
                value={this.state.linkedin || "http://linkedin.com/"}
                onChange={ event => this.handleChange(event.target.value,  "linkedin") }
              />
            </FormControl>
          </GridItem>
          <GridItem xs={12} sm={2}>
            <Close onClick={() => this.handleChange(false, 'linkedinState')} className={classes.socialD} />
          </GridItem>
        </GridContainer> }

      </GridContainer>
    );
  }
}

export default withTranslation('',{ withRef: true })(Step1);