import React from "react";

export default function TextPill({ color = "#078f5c", text, style }) {
    if (!text) return null;
    return (
        <div
            style={{
                display: "inline-flex",
                lineHeight: 1,
                backgroundColor: color + "33",
                padding: "4px 10px",
                textAlign: "center",
                borderRadius: 1000,
                color,
                fontWeight: 600,
                whiteSpace: "nowrap",
                ...style,
            }}
        >
            {text}
        </div>
    );
}
