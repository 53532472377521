import React, {useEffect} from 'react';
import { useDispatch, useSelector} from 'react-redux';
import SweetAlert from "react-bootstrap-sweetalert";
import {withTranslation} from 'react-i18next';
import { Permissions } from '../../config'

// material-ui icons
import {Assignment, Edit, AddAlert, Search} from "@mui/icons-material";
import {createUseStyles} from "react-jss";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import SnackbarCustom from "components/Snackbar/Snackbar.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import DeleteButton from "components/Main/DeleteButton.js";

import stylesAlert from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import styles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";

import {getCategories, deleteCategory, createUpdateCategory, searchCategories} from "../../store/actions/category";
import AddCategory from "./addCategory";
import {LoaderSpinner} from "../../components/Loader";

const style = {
  ...styles,
  ...stylesAlert
};

const useStyles = createUseStyles(style);

const Categories = ({ t }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const {categories, count, loading} = useSelector( state => state.category );
  const {status, message, type} = useSelector( state => state.category.notification );
  const [alert, setAlert] = React.useState(null);
  const [search, setSearch] = React.useState('');
  const userPermission = JSON.parse(localStorage.getItem('user')).permission;
  const CATEGORY_UPDATE = userPermission.includes(Permissions.CATEGORY_UPDATE);
  const CATEGORY_DELETE = userPermission.includes(Permissions.CATEGORY_DELETE);

  useEffect(() => {
    dispatch(getCategories());
  }, [dispatch]);

  const hideAlert = () => { setAlert(null) };

  const searchHandler = () => {
    if(search.length > 1) {
      let newSearch = search.replace(/%/g, '');
      dispatch(searchCategories(newSearch));
      return;
    }
    setAlert(
      <SweetAlert
        style={{ display: "block", marginTop: "-100px" }}
        title="The search text must contain at least 2 characters"
        onConfirm={() => hideAlert()}
        showConfirm={false}
      />
    );
    setTimeout(hideAlert, 2000);
  };

  const onSubmit = (data, update) => {
    dispatch(createUpdateCategory({update:update, ...data}));
  };

  const updateButton = (data) => {
    return (
        <Button color="success" key={data.id + 'update'} className={classes.actionButton} onClick={() => htmlAlert(data)} title={t('edit')}>
          <Edit className={classes.icon} />
        </Button>
    );
  };

  const htmlAlert = (data) => {
    setAlert(
      <AddCategory classes={classes} categories={categories[0].children} data={data} onCancel={() => hideAlert()} onSubmit={(data, update) => onSubmit(data, update)}/>
    );
  };

  if (loading) return (<LoaderSpinner/>);

  const tableData = () => {
    if (categories.length === 0) return [[ ]];
    let data = [];
    categories[0].children.map((value, index) => {
      data.push({
        color: "info",
        data: [
          value.id,
          <img src={value.logo} style={{width: '50px', height: '50px'}}/>,
          value.name,
          value.name_en,
          'Parent category',
          [
            CATEGORY_UPDATE && updateButton(value),
            CATEGORY_DELETE && <DeleteButton key={value.id+'_delete'} action={deleteCategory({...value})} setLimit={()=>{}} />
          ]
        ]
      });
      let categoryData = categories.find(item => item.parent_id == value.id);
      categoryData && categoryData.children.map((value, index) => {
        data.push ([
          index+1,
          <img src={value.icon} style={{width: '25px', height: '25px'}}/>,
          value.name,
          value.name_en,
          value.parent_id,
          [
            CATEGORY_UPDATE && updateButton(value),
            CATEGORY_DELETE && <DeleteButton key={value.id+'_delete'} action={deleteCategory({...value})} setLimit={()=>{}} />
          ]
        ]);
      });
    });
    return data;
  };

  return  <GridContainer>
    <div style={{zIndex: "1!important"}}> {alert} </div>
    <SnackbarCustom place="br" icon={AddAlert} color={type === 'success' ? 'success' : 'warning'} message={message} open={status}/>
    <GridItem xs={12}>
      <Card>
        <CardHeader color="info" icon>
          <GridContainer  justifyContent="space-between" container>
            <GridItem md={3}>
              <CardHeader>
                <CardIcon color="rose"> <Assignment /> </CardIcon>
                <span className={classes.tableTitle}>{t('menu.categories')} </span>
                <span className={classes.tableCount} >  {`Sýni ${count} af ${count} flokkum`} </span>
              </CardHeader>
            </GridItem>
            <GridItem>
              <CustomInput
                inputProps={{
                  value: search,
                  onChange: event => setSearch(event.target.value),
                  onKeyPress: e => (e.key === 'Enter' && searchHandler()),
                  placeholder: t('search'),
                  inputProps: { "aria-label": "Search",  className: classes.searchInput }
                }}
              />
              <Button color="rose" aria-label="edit" justIcon size="sm" onClick={() => searchHandler()} >
                <Search className={classes.headerLinksSvg + " " + classes.searchIcon} />
              </Button>
            </GridItem>
            <GridItem>
              { userPermission.includes(Permissions.CATEGORY_CREATE) && <Button color="rose" className={classes.addButton} onClick={htmlAlert} > {t('category.add_new')} </Button> }
            </GridItem>
          </GridContainer>
        </CardHeader>
        <CardBody>
          <Table
            striped
            tableHead={[ "ID",  t('icon'), t('name'), t('name_en'),  t('parent_id'),  t('actions') ]}
            tableData={ tableData() }
            customCellClasses={[classes.center, classes.right, classes.right]}
            customClassesForCells={[0, 4, 5]}
            customHeadCellClasses={[classes.center, classes.right, classes.right ]}
            customHeadClassesForCells={[0, 4, 5]}
          />
        </CardBody>
      </Card>
    </GridItem>
  </GridContainer>
};

export default withTranslation('',{ withRef: true })(Categories);