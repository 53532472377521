import React from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import {withTranslation} from 'react-i18next';

// @material-ui
import {TextareaAutosize, Checkbox} from "@mui/material";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import FileUpload from "components/CustomUpload/FileUpload.js";

class addSpecialUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      users:  "",
      usersState: "",
      companies: [],
      companiesState:  "",
      torgid_company_id: '',
      msg: ""
    };
  }

  isValidated() {
    if (
      this.state.msg === 'Allt rétt!' &&
      this.state.companiesState === "success"
    ) {
      return true;
    } else {
      if (this.state.msg !== 'Allt rétt!') {
        this.setState({ msg: "Vinsamlega fylltu reitinn út" });
      }
      if (this.state.companiesState !== "success") {
        this.setState({ companiesState: "error" });
      }
    }
    return false;
  }

  inputConfirmAlertNext = e => {
    if (this.isValidated()) {
      this.props.onSubmit(this.state);
      this.props.onCancel();
    }
  };

  verifyEmail = (value) => {
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailRex.test(value);
  };
  verifyPhone = (value) => {
    var phoneRex =  /^\d{7}$/;
    return phoneRex.test(value);
  };
  verifyKennitala = (value) => {
    var kennitalaRex =  /^\d{10}$/;
    return kennitalaRex.test(value);
  };

  change(data) {
    let result = data.replace(/^\s+|\s+$/g, '').split(/\s+/);
    this.setState({ users: data });
    let temp = [];
    for(var i = 0; i < result.length; i++) {
      if(this.verifyEmail(result[i]) || this.verifyPhone(result[i]) || this.verifyKennitala(result[i])) {
        temp.push('ok');
      }
      else {
        temp.push('error');
      }
    }
    if(temp.includes('error')) {
      this.setState({ msg: "Villa í innslætti í '"+result[temp.indexOf('error')]+"'" });
    } else {
      this.setState({ msg: "Allt rétt!" });
    }

  }


  handleChange = (company_id) => {
    let comps = this.state.companies;
    if(!comps.includes(company_id)){
      comps.push(company_id);
    }else{
      comps.splice(comps.indexOf(company_id), 1);
    }
    this.setState({ companies: comps });
    this.setState({ companiesState: "success" });
  };


  render() {
    const {classesAdd, companies, t } = this.props;
    return (
      <SweetAlert
        showCancel
        cancelBtnText={t('cancel')}
        confirmBtnText={t('save')}
        style={{ display: "block", marginTop: "-100px", width: '700px' }}
        title={ t('add_new_special_user') }
        onCancel={() => this.props.onCancel()}
        onConfirm={e => {this.inputConfirmAlertNext(); }}
        confirmBtnCssClass={classesAdd.button + " " + classesAdd.success}
        cancelBtnCssClass={classesAdd.button + " " + classesAdd.danger}
        closeOnClickOutside={false}
      >
        <GridContainer justifyContent="space-between" container>
          <GridItem xs={12} sm={12} className={this.state.msg === 'Allt rétt!' ? classesAdd.txtSuccess : classesAdd.error}>
            {this.state.msg}
          </GridItem>
          <GridItem xs={12} sm={12}>
            <TextareaAutosize
              className={classesAdd.txtArea}
              minRows={8}
              maxRows={8}
              id="description"
              aria-label="description"
              placeholder={t('add_employee_example')}
              value={this.state.users}
              onChange={event => this.change(event.target.value)}
            />
          </GridItem>

          <GridItem xs={12} sm={12}>
            <FileUpload onUpload={(data) => this.change(data)} />
          </GridItem>

          <GridItem xs={12} sm={12} className={this.state.companiesState === "error" ? classesAdd.error : classesAdd.txtSuccess}>
            { this.state.companiesState === "error" && 'Error: Please choose company' }
          </GridItem>
          <GridItem xs={12} sm={12} className={classesAdd.companies}>
            {companies && companies.map((comp, compIndex) => {
              return <div className={classesAdd.choiche} key={compIndex + 'spec'}>
                <Checkbox
                  checked={!!(this.state.companies.includes(comp.id))}
                  tabIndex={-1}
                  title={comp.name}
                  alt={comp.name}
                  onClick={() => this.handleChange(comp.id)}
                  checkedIcon={<img key={compIndex + 'img1'} src={comp.logo} className={classesAdd.logoImg} />}
                  icon={<img key={compIndex + 'img2'} src={comp.logo} className={classesAdd.logoImg}/>}
                  classes={{checked: classesAdd.iconSelected, root: classesAdd.iconCheckbox}}
                />
              </div>
            })
            }
          </GridItem>

        </GridContainer>
      </SweetAlert>
    );
  }
}

export default withTranslation('',{ withRef: true })(addSpecialUser);