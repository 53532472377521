import { defaultFont, whiteColor } from "../../material-dashboard-pro-react.js";

import customDropdownStyle from "./customDropdownStyle.js";

const adminNavbarLinksStyle = (theme) => ({
    ...customDropdownStyle(theme),
    search: {
        margin: "0",
        paddingTop: "7px",
        paddingBottom: "7px",
        "@media (max-width: 600px)": {
            margin: "10px 15px",
            float: "none !important",
            paddingTop: "1px",
            paddingBottom: "1px",
            padding: "10px 15px",
            width: "auto",
        },
    },
    searchInput: {
        paddingTop: "2px",
    },
    searchRTL: {
        "@media (max-width: 600px)": {
            marginRight: "18px !important",
        },
        "@media (min-width: 900px)": {
            marginLeft: "12px",
        },
    },
    linkText: {
        zIndex: "4",
        ...defaultFont,
        fontSize: "14px",
        margin: "0!important",
        textTransform: "none",
        color: "#fff",
    },
    buttonLink: {
        "@media (max-width: 600px)": {
            display: "flex",
            margin: "5px 15px 0",
            width: "auto",
            height: "auto",
            "& svg": {
                width: "30px",
                height: "24px",
                marginRight: "19px",
                marginLeft: "3px",
            },
            "& .fab,& .fas,& .far,& .fal,& .material-icons": {
                width: "30px",
                fontSize: "24px",
                lineHeight: "30px",
                marginRight: "19px",
                marginLeft: "3px",
            },
        },
    },
    searchButton: {
        "@media (max-width: 600px)": {
            top: "-50px !important",
            marginRight: "38px",
            float: "right",
        },
    },
    top: {
        zIndex: "4",
    },
    searchIcon: {
        width: "17px",
        zIndex: "4",
    },
    links: {
        width: "20px",
        height: "20px",
        zIndex: "4",
        "@media (max-width: 600px)": {
            display: "block",
            // width: "30px",
            // height: "30px",
            color: "inherit",
            opacity: "0.8",
            marginRight: "16px",
            marginLeft: "-5px",
        },
    },
    notifications: {
        zIndex: "4",
        "@media (min-width: 900px)": {
            position: "absolute",
            top: "5px",
            border: "1px solid " + whiteColor,
            right: "5px",
            fontSize: "9px",
            background: "#de2c48",
            color: "#fff",
            minWidth: "16px",
            height: "16px",
            borderRadius: "10px",
            textAlign: "center",
            lineHeight: "14px",
            verticalAlign: "middle",
            display: "block",
        },
        "@media (max-width: 600px)": {
            ...defaultFont,
            fontSize: "14px",
            marginRight: "8px",
        },
    },
    wrapperRTL: {
        "@media (min-width: 900px)": {
            paddingLeft: "16px",
        },
    },
    buttonLinkRTL: {
        "@media (max-width: 600px)": {
            alignItems: "center",
            justifyContent: "flex-end",
            width: "-webkit-fill-available",
            margin: "10px 15px 0",
            padding: "10px 15px",
            display: "block",
            position: "relative",
        },
    },
    labelRTL: {
        "@media (max-width: 600px)": {
            flexDirection: "row-reverse",
            justifyContent: "initial",
            display: "flex",
        },
    },
    linksRTL: {
        "@media (max-width: 600px)": {
            marginRight: "-5px !important",
            marginLeft: "16px !important",
        },
    },
    managerClasses: {
        "@media (min-width: 600px)": {
            display: "inline-block",
        },
    },
    headerLinksSvg: {
        width: "20px !important",
        height: "20px !important",
        color: "#de2c48",
    },
});

export default adminNavbarLinksStyle;
