import React from "react";
import styles from "./HoverInfoBox.module.scss";
import { Help } from "@mui/icons-material";

const HelpIcon = () => {
    return (
        <Help
            style={{
                lineHeight: "1rem",
                color: "gray",
                height: "1rem",
                width: "1rem",
            }}
        />
    );
};

const HoverInfoBox = ({ hoverComponent = <HelpIcon />, children, style }) => {
    return (
        <div className={styles.hoverContainer} {...style}>
            <div className={styles.hoverElement}>{hoverComponent}</div>
            <div className={styles.infoBox}>{children}</div>
        </div>
    );
};

export default HoverInfoBox;
