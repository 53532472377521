export const GROUP_TYPE_COLORS = {
    lite: "#00A86B",
    golden: "#D09600",
    regular: "#0f8cbd",
};

export const GROUP_TYPE_LABELS = {
    lite: "group_type_lite",
    golden: "group_type_golden",
    regular: "group_type_regular",
};
