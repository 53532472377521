import React from "react";
import Datetime from "react-datetime";
import { withTranslation } from "react-i18next";

import "rc-time-picker/assets/index.css";
import axios from "axios";
import SweetAlert from "react-bootstrap-sweetalert";

// @material-ui
import {
    FormControlLabel,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    InputAdornment,
    Radio,
    RadioGroup,
    Checkbox,
    TextField,
} from "@mui/material";

// core components
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Autocomplete from "@mui/material/Autocomplete";
import SingleImageUpload from "components/SingleImageUpload/SingleImageUpload";
import { FlexContainer } from "components/FlexContainer/FlexContainer";
import HoverInfoBox from "components/HoverInfoBox/HoverInfoBox";
import styles from "./Step1.module.scss";

const activateOfferType = {
    email: "Netfang sem á að senda á",
    website: "Settu vefsíðu hér",
    promocode: "Settu kóða hér",
    phone: "Settu símanúmer hér",
};

const activationHelpTexts = {
    timer: "Notendur sýna starfsfólki skjá sem inniheldur niðurtalningu.",
    promocode:
        "Notendur nota afsláttarkóða í netverslun. Hægt er að setja hlekk beint inn á tilboðið sem notandi smellir á þegar hann virkjar tilboðið í appinu.",
    email:
        "Notendur senda tölvupóst á netfangið sem þú setur hér. Heiti tilboðsins kemur sem Efni (e. Subject) í tölvupóstinum.",
    phone: "Notendur hringja í símanúmerið sem þú gefur upp til að virkja tilboðið.",
    website:
        "Notendur opna vefsíðuna sem þú gefur upp til að virkja tilboðið. Getur verið beinn hlekkur á ákveðið tilboð.",
    barcode:
        "Notendur þurfa að sýna strikamerki til þess að virkja tilboðið. Settu inn þær upplýsingar sem eiga að koma fram í strikamerkinu.",
    qrcode:
        "Notendur þurfa að sýna QR kóða til þess að virkja tilboðið. Settu inn þær upplýsingar sem eiga að koma fram í QR kóðanum.",
};

class Step1 extends React.Component {
    constructor(props) {
        super(props);
        let extraImages =
            props.offerData.offer_images && props.offerData.offer_images.length > 0
                ? props.offerData.offer_images
                : [];
        // If props.offerData.image contains the same image as one of the extra images, remove it from the extra images
        if (props.offerData.image) {
            extraImages = extraImages.filter((x) => x.image !== props.offerData.image);
        }
        this.store = JSON.parse(localStorage.getItem("user"));
        this.user_is_group_admin = !!(this.store && this.store.roles.includes("group_admin"));
        this.user_is_company_user = !!(this.store && this.store.roles.includes("company_user"));
        this.user_is_group_id = this.store.user.torgid_special_company_id;

        let hasImages = false;

        if (props.offerData.offer_images && props.offerData.offer_images.length > 0) {
            hasImages = true;
        }
        if (props.offerData.image) {
            hasImages = true;
        }
        let discount = null;
        if (!props?.offerData?.discount) {
            discount = "discount";
        }
        if (props?.offerData?.discount?.includes?.("%")) {
            discount = "discount";
        }
        if (props?.offerData?.discount?.includes?.("kr.")) {
            discount = "price";
        }

        this.state = {
            alert: "",
            torgid_user_id: JSON.parse(localStorage.getItem("user")).user.torgid_user_id,
            id: props.offerData.id || "",
            title: props.offerData.title || "",
            titleState: props.offerData.title ? "success" : "",
            title_en: props.offerData.title_en || "",
            description: props.offerData.description || "",
            descriptionState: props.offerData.description ? "success" : "",
            description_en: props.offerData.description_en || "",
            discount,
            discountInput: !props.offerData.discount
                ? ""
                : props.offerData.discount.includes("%")
                ? props.offerData.discount.slice(0, -1)
                : props.offerData.discount.includes("kr.")
                ? props.offerData.discount.slice(0, -3)
                : "",
            // special_discount: !props.offerData.special_discount ? "" : props.offerData.special_discount.includes('%') ? props.offerData.special_discount.slice(0, -1)  : props.offerData.special_discount,
            approved: props.offerData.id ? props.offerData.approved : false,
            image: props.offerData.image || "",
            images: extraImages,

            imagesState: hasImages ? "success" : "error",
            action_type: props.offerData.action_type || "timer",
            action_value: props.offerData.action_value || "",
            promocode: props.offerData.promocode || "",
            offer_link: props.offerData.offer_link || "",
            is_active: props.offerData.id ? props.offerData.is_active : true,
            start_date: props.offerData.start_date || "",
            end_date: props.offerData.end_date || "",
            torgid_offer_active_times: [
                {
                    active_day: 0,
                    day: "mán.",
                    start_time: "00:00",
                    end_time: "23:59",
                    active: props.offerData.id ? false : true,
                    active24: true,
                },
                {
                    active_day: 1,
                    day: "þri.",
                    start_time: "00:00",
                    end_time: "23:59",
                    active: props.offerData.id ? false : true,
                    active24: true,
                },
                {
                    active_day: 2,
                    day: "mið.",
                    start_time: "00:00",
                    end_time: "23:59",
                    active: props.offerData.id ? false : true,
                    active24: true,
                },
                {
                    active_day: 3,
                    day: "fim.",
                    start_time: "00:00",
                    end_time: "23:59",
                    active: props.offerData.id ? false : true,
                    active24: true,
                },
                {
                    active_day: 4,
                    day: "fös.",
                    start_time: "00:00",
                    end_time: "23:59",
                    active: props.offerData.id ? false : true,
                    active24: true,
                },
                {
                    active_day: 5,
                    day: "lau.",
                    start_time: "00:00",
                    end_time: "23:59",
                    active: props.offerData.id ? false : true,
                    active24: true,
                },
                {
                    active_day: 6,
                    day: "sun.",
                    start_time: "00:00",
                    end_time: "23:59",
                    active: props.offerData.id ? false : true,
                    active24: true,
                },
            ],
            active_times_status: false,
            torgid_special_company_id: props.offerData.torgid_special_company
                ? props.offerData.torgid_special_company.id
                : this.user_is_group_admin
                ? this.user_is_group_id
                : 0,
            companyId:
                props.offerData.id && props.offerData.torgid_company
                    ? props.offerData.torgid_company.id
                    : this.user_is_company_user
                    ? JSON.parse(localStorage.getItem("user")).user.torgid_company_id
                    : "",
            companyState:
                props.offerData.id && props.offerData.torgid_company
                    ? "success"
                    : this.user_is_company_user
                    ? "success"
                    : "",
            companies: JSON.parse(localStorage.getItem("user")).companies,
            torgid_special_company_ids: !props.offerData.id
                ? this.store.roles.length === 1 &&
                  this.store.roles[0] === "group_admin" &&
                  this.store.groupIds.length === 1
                    ? this.store.groupIds
                    : []
                : props.offerData.group_ids
                ? props.offerData.group_ids.split(",").map((x) => +x)
                : props.offerData.torgid_special_company_id
                ? [props.offerData.torgid_special_company_id]
                : [0],
            groupState:
                props.offerData.group_ids || this.user_is_group_admin
                    ? "success"
                    : props.offerData.id && props.offerData.group_ids === null
                    ? "success"
                    : "",
        };
    }

    componentDidMount() {
        if (this.props.offerData.torgid_offer_active_times) {
            let activeDaysState = [...this.state.torgid_offer_active_times];
            this.props.offerData.torgid_offer_active_times.forEach((value) => {
                if (value.start_time.length === 8) {
                    // remove the seconds from the time
                    value.start_time = value.start_time.slice(0, -3);
                }
                if (value.end_time.length === 8) {
                    // remove the seconds from the time
                    value.end_time = value.end_time.slice(0, -3);
                }
                activeDaysState[value.active_day].start_time = value.start_time;
                activeDaysState[value.active_day].end_time = value.end_time;
                activeDaysState[value.active_day].active = true;
                if (value.start_time === "00:00" && value.end_time === "23:59") {
                    activeDaysState[value.active_day].active24 = true;
                }
            });
            this.setState({ torgid_offer_active_times: activeDaysState });
        }
        if (!this.state.id && this.state.companyId && !this.user_is_group_admin) {
            this.props.getData(this.state.companyId);
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (
            !props.offerData.id &&
            state.companyId &&
            props.data2 &&
            props.data2.results &&
            state.active_times_status
        ) {
            let activeDays = [
                {
                    active_day: 0,
                    day: "mán.",
                    start_time: "00:00",
                    end_time: "23:59",
                    active: props.offerData.id ? false : true,
                    active24: true,
                },
                {
                    active_day: 1,
                    day: "þri.",
                    start_time: "00:00",
                    end_time: "23:59",
                    active: props.offerData.id ? false : true,
                    active24: true,
                },
                {
                    active_day: 2,
                    day: "mið.",
                    start_time: "00:00",
                    end_time: "23:59",
                    active: props.offerData.id ? false : true,
                    active24: true,
                },
                {
                    active_day: 3,
                    day: "fim.",
                    start_time: "00:00",
                    end_time: "23:59",
                    active: props.offerData.id ? false : true,
                    active24: true,
                },
                {
                    active_day: 4,
                    day: "fös.",
                    start_time: "00:00",
                    end_time: "23:59",
                    active: props.offerData.id ? false : true,
                    active24: true,
                },
                {
                    active_day: 5,
                    day: "lau.",
                    start_time: "00:00",
                    end_time: "23:59",
                    active: props.offerData.id ? false : true,
                    active24: true,
                },
                {
                    active_day: 6,
                    day: "sun.",
                    start_time: "00:00",
                    end_time: "23:59",
                    active: props.offerData.id ? false : true,
                    active24: true,
                },
            ];
            if (props.data2.results.torgid_company_locations.length > 0) {
                props.data2.results.torgid_company_locations[0].torgid_company_openings.forEach(
                    (value) => {
                        if (value.start_time.length === 8) {
                            // remove the seconds from the time
                            value.start_time = value.start_time.slice(0, -3);
                        }
                        if (value.end_time.length === 8) {
                            // remove the seconds from the time
                            value.end_time = value.end_time.slice(0, -3);
                        }

                        activeDays[value.day].start_time = value.open_time;
                        activeDays[value.day].end_time = value.close_time;
                        activeDays[value.day].active = true;

                        if (value.start_time === "00:00" && value.end_time === "23:59") {
                            activeDays[value.day].active24 = true;
                        }
                    }
                );
            }
            return { torgid_offer_active_times: activeDays, active_times_status: false };
        }
        return null;
    }

    sendState() {
        return this.state;
    }

    verifyLength(value, length) {
        return value.length >= length;
    }

    handleChange = (data, type) => {
        console.log(data, type);

        this.setState({ [type]: data });
    };

    handleChangeOption = (event) => {
        const {
            target: { value },
        } = event;
        let last = value[value.length - 1];
        if (last === 0 || last === 1 || last === 3) {
            this.setState({ torgid_special_company_ids: [last] });
        } else {
            if (value.indexOf(0) > -1 || value.indexOf(1) > -1 || value.indexOf(3) > -1) {
                this.setState({ torgid_special_company_ids: [last] });
            } else {
                this.setState({ torgid_special_company_ids: value });
            }
        }
        this.setState({ groupState: "success" });
    };

    handleChangeGroup = (data) => {
        this.setState({ torgid_special_company_id: data });
        this.setState({ groupState: "success" });
    };

    handleChangeCompanyGroup = (data, type) => {
        if (!data || data === null) {
            this.setState({
                [type]: null,
                companyState: "error",
            });
            return;
        }
        if (!this.state.id && !this.user_is_group_admin) {
            this.props.getData(data.id);
        }
        this.setState({
            [type]: data.id,
            companyState: "success",
            active_times_status: true,
        });
    };

    handleChangeCompany = (data, type) => {
        if (!this.state.id && !this.user_is_group_admin) {
            this.props.getData(data);
        }
        this.setState({
            [type]: data,
            companyState: "success",
            active_times_status: true,
        });
    };

    onUpload(data, index, id) {
        let images = this.state.images;
        // If index is more than the length of the images array, then it is a new image
        if (index >= images.length) {
            images.push({
                id: null,
                image: data,
                sort_order: this.state.images.length + 2,
            });
        }

        if (
            images[index].image &&
            images[index].image !== "" &&
            images?.[index]?.image?.includes("http")
        ) {
            images[index].deleted = true;
            images.push({
                id: null,
                image: data,
                sort_order: this.state.images.length + 2,
            });
        } else {
            images[index].id = id;
            images[index].image = data;
            images[index].sort_order = index + 2;
        }

        this.setState({
            images: images,
            imagesState: "success",
        });
    }

    changeImageOrder(currentIndex, newIndex) {
        let images = this.state.images;
        let currentItem = images[currentIndex];
        images.splice(currentIndex, 1);
        images.splice(newIndex, 0, currentItem);
        this.setState({ images: images });
    }

    deleteImageItem(index) {
        let images = [...this.state.images];
        if (images[index].id) {
            images[index].deleted = true;
            images.splice(index, 1);
        } else {
            images.splice(index, 1);
        }

        this.setState({ images: images }, () => {
            this.validateImages();
        });
    }

    addNewImage = () => {
        this.setState({
            images: [
                ...this.state.images,
                {
                    id: null,
                    image: "",
                    sort_order: this.state.images.length + 2,
                },
            ],
        });
    };

    change(event, stateName, type, stateNameEqualTo) {
        if (type === "length") {
            if (this.verifyLength(event.target.value, stateNameEqualTo)) {
                this.setState({ [stateName + "State"]: "success" });
            } else {
                this.setState({ [stateName + "State"]: "error" });
            }
        }
        this.setState({ [stateName]: event.target.value });
    }
    hideAlert = () => {
        this.setState({ alert: null });
    };
    isValidated() {
        console.log(this.state.imagesState);

        if (
            this.state.titleState === "success" &&
            this.state.descriptionState === "success" &&
            this.state.companyState === "success" &&
            this.state.imagesState === "success" &&
            this.state.groupState === "success"
        ) {
            return true;
        } else {
            if (this.state.titleState !== "success") {
                this.setState({ titleState: "error" });

                this.setState({
                    alert: (
                        <SweetAlert
                            style={{ display: "block", marginTop: "-100px" }}
                            title="Vinsamlega settu inn titil"
                            onConfirm={() => this.hideAlert()}
                            showConfirm={false}
                        />
                    ),
                });
                setTimeout(this.hideAlert, 2000);
                return false;
            }
            if (this.state.descriptionState !== "success") {
                this.setState({ descriptionState: "error" });
                this.setState({
                    alert: (
                        <SweetAlert
                            style={{ display: "block", marginTop: "-100px" }}
                            title="Vinsamlega settu inn lýsingu"
                            onConfirm={() => this.hideAlert()}
                            showConfirm={false}
                        />
                    ),
                });
                setTimeout(this.hideAlert, 2000);
                return false;
            }
            if (this.state.companyState !== "success") {
                this.setState({ companyState: "error" });
                this.setState({
                    alert: (
                        <SweetAlert
                            style={{ display: "block", marginTop: "-100px" }}
                            title="Vinsamlega veldu fyrirtæki"
                            onConfirm={() => this.hideAlert()}
                            showConfirm={false}
                        />
                    ),
                });
                setTimeout(this.hideAlert, 2000);
                return false;
            }
            if (this.state.imagesState !== "success") {
                this.setState({ imagesState: "error" });
                this.setState({
                    alert: (
                        <SweetAlert
                            style={{ display: "block", marginTop: "-100px" }}
                            title="Vinsamlega veldu mynd"
                            onConfirm={() => this.hideAlert()}
                            showConfirm={false}
                        />
                    ),
                });
                setTimeout(this.hideAlert, 2000);
                return false;
            }
            if (this.state.groupState !== "success") {
                this.setState({ groupState: "error" });
                this.setState({
                    alert: (
                        <SweetAlert
                            style={{ display: "block", marginTop: "-100px" }}
                            title="Vinsamlega veldu hóp"
                            onConfirm={() => this.hideAlert()}
                            showConfirm={false}
                        />
                    ),
                });
                setTimeout(this.hideAlert, 2000);
                return false;
            }
        }
        return false;
    }

    handleActiveTime(index) {
        let activeTimes = [...this.state.torgid_offer_active_times];
        activeTimes[index] = {
            active_day: index,
            day: activeTimes[index].day,
            start_time: activeTimes[index].start_time,
            end_time: activeTimes[index].end_time,
            active: !activeTimes[index].active,
            active24: activeTimes[index].active24,
        };
        this.setState({ torgid_offer_active_times: activeTimes });
    }

    handleActiveTimeDef(index) {
        let activeTimes = [...this.state.torgid_offer_active_times];
        activeTimes[index] = {
            active_day: index,
            day: activeTimes[index].day,
            start_time: "00:00",
            end_time: "23:59",
            active: true,
            active24: true,
        };
        this.setState({ torgid_offer_active_times: activeTimes });
    }

    handleChangeTime = (e, index, type) => {
        console.log(e, index, type);

        let val = e;
        let activeTimes = [...this.state.torgid_offer_active_times];
        activeTimes[index] = {
            active_day: index,
            day: activeTimes[index].day,
            start_time: type === "st" ? val : activeTimes[index].start_time,
            end_time: type === "et" ? val : activeTimes[index].end_time,
            active: true,
        };

        // check if start time and end time are 00:00 and 23:59
        if (activeTimes[index].start_time === "00:00" && activeTimes[index].end_time === "23:59") {
            activeTimes[index].active24 = true;
        } else {
            activeTimes[index].active24 = false;
        }

        this.setState({ torgid_offer_active_times: activeTimes });
    };

    autoTranslate = (state, type, fromLn, toLn) => {
        let textOld = this.state[state];
        let text = textOld.replace(/&/g, "");
        axios
            .get(
                `https://translation.googleapis.com/language/translate/v2?key=AIzaSyA5AF_VTEbn4TXQ2uMWsaEx0Al53mT7aOU&q=${text}&source=${fromLn}&target=${toLn}`
            )
            .then((response) => {
                if (response.status === 200) {
                    let translatedText = response.data.data.translations[0].translatedText;
                    this.setState({
                        [type]: translatedText.replace(new RegExp("&#39;", "g"), "'"),
                    });
                } else {
                    this.setState({
                        alert: (
                            <SweetAlert
                                title={response.statusText}
                                onConfirm={() => this.hideAlert()}
                                showConfirm={false}
                                closeOnClickOutside={true}
                            />
                        ),
                    });
                    setTimeout(this.hideAlert, 2000);
                }
            });
    };

    handleDeleteMainImage = () => {
        let images = this.state.images;
        let newImage = "";
        if (images.length > 0) {
            // Remove the first element of the images array
            newImage = images[0].image;
            images.shift();
        } else {
            newImage = "";
        }
        this.setState({ image: newImage }, () => {
            this.validateImages();
        });
    };

    validateImages = () => {
        let hasImages = false;

        if (this.state.images && this.state.images.length > 0) {
            hasImages = true;
        }
        if (this.state.image) {
            hasImages = true;
        }
        this.setState({ imagesState: hasImages ? "success" : "error" });
    };

    onUploadMainImage(data) {
        this.setState({
            image: data,
            imagesState: "success",
        });
    }

    handleSelectAllActiveTimes = (e) => {
        let activeTimes = [...this.state.torgid_offer_active_times];
        activeTimes.map((value) => {
            value.active = true;
            value.active24 = true;
            value.start_time = "00:00";
            value.end_time = "23:59";
            return value;
        });
        this.setState({ torgid_offer_active_times: activeTimes });
    };

    render() {
        const { classes, data, companies, t } = this.props;
        const renderActiveTime =
            this.state.torgid_offer_active_times &&
            this.state.torgid_offer_active_times.map((value, index) => {
                return (
                    <tr style={{ height: 34 }}>
                        <td style={{ padding: 0 }}>
                            <Checkbox
                                style={{
                                    padding: 0,
                                    margin: 0,
                                    color: value?.active ? "#DE2C48" : "gray",
                                }}
                                onClick={() => this.handleActiveTime(index)}
                                checked={value?.active}
                            />
                        </td>
                        <td>{this.props.t(value.day)}</td>

                        <td style={{ padding: 0 }}>
                            {value?.active && (
                                <FlexContainer
                                    style={{
                                        marginLeft: 6,
                                        alignItems: "center",
                                        flexWrap: "nowrap",
                                    }}
                                >
                                    <HourInput
                                        value={
                                            this.state.torgid_offer_active_times[index].start_time
                                        }
                                        placeholder={t.opens}
                                        onChange={(e) => this.handleChangeTime(e, index, "st")}
                                    />
                                    <span
                                        style={{
                                            textAlign: "center",
                                            padding: 0,
                                            margin: "0px 6px",
                                        }}
                                    >
                                        −
                                    </span>
                                    <HourInput
                                        value={this.state.torgid_offer_active_times[index].end_time}
                                        placeholder={t.opens}
                                        onChange={(e) => this.handleChangeTime(e, index, "et")}
                                    />
                                </FlexContainer>
                            )}
                        </td>
                        <td style={{ padding: 0 }}>
                            {" "}
                            {value.active && (
                                <Button
                                    size="sm"
                                    onClick={() => this.handleActiveTimeDef(index)}
                                    style={{ margin: 0, padding: "4px 8px", marginLeft: 8 }}
                                    color={value.active && value.active24 ? "success" : "default"}
                                >
                                    Allan daginn
                                </Button>
                            )}
                        </td>
                    </tr>
                );
            });

        const optionsCompany =
            companies.results &&
            companies.results
                .filter((item) => item.id !== 1305)
                .map((option) => {
                    const firstLetter = option.name.charAt(0).toUpperCase();
                    return {
                        firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
                        ...option,
                    };
                });
        console.log("OC", optionsCompany);

        // Check if offer is active on all days with active24
        let alwaysActive = true;

        this.state.torgid_offer_active_times.forEach((value) => {
            if (!value.active24 || !value.active) {
                alwaysActive = false;
            }
        });

        const activationHelpText = activationHelpTexts[this.state.action_type];

        return (
            <>
                {this.state.alert}
                <FlexContainer
                    column
                    justifyContent="space-between"
                    style={{ padding: "0px 20px" }}
                    className={styles.container}
                >
                    {this.state.alert}
                    <FlexContainer
                        justifyContent="space-between"
                        style={{ margin: "0px 0px", flexGrow: 1 }}
                    >
                        <FlexContainer column style={{ flex: 1 }}>
                            <FlexContainer style={{ alignItems: "center" }}>
                                <h3
                                    style={{
                                        color: "#DE2C48",
                                        fontWeight: 500,
                                        marginTop: 0,
                                        marginRight: 8,
                                    }}
                                >
                                    {t("images")}
                                </h3>
                                <HoverInfoBox>
                                    Ákjósanleg stærð aðalmyndar er 900x600px eða hlutföllin 3:2. Má
                                    ekki vera stærri en 800 KB.
                                    <br style={{ marginBottom: 10 }} />
                                    Best er að texti eða aðrar mikilvægar upplýsingar fari ekki inn
                                    á bláa/hvíta svæðið.
                                    <br style={{ marginBottom: 10 }} />
                                    Það svæði birtist ekki í öllum tilboðsútgáfum í appinu.
                                </HoverInfoBox>
                            </FlexContainer>
                            <div className={styles.imagesContainer}>
                                <div className={styles.mainImageContainer}>
                                    <SingleImageUpload
                                        image={this.state.image}
                                        onUpload={(data) => this.onUploadMainImage(data)}
                                        onDelete={() => this.handleDeleteMainImage()}
                                        style={{
                                            aspectRatio: "3/2",
                                        }}
                                        overlay={
                                            <>
                                                <div
                                                    style={{
                                                        position: "absolute",
                                                        top: 0,
                                                        left: 0,
                                                        aspectRatio: "50/200",
                                                        background:
                                                            "repeating-linear-gradient(45deg,#007AAA, #007AAA 8px, white 8px, white 16px)",
                                                        opacity: 0.4,
                                                        height: "100%",
                                                    }}
                                                />
                                                <div
                                                    style={{
                                                        position: "absolute",
                                                        top: 0,
                                                        right: 0,
                                                        aspectRatio: "50/200",
                                                        background:
                                                            "repeating-linear-gradient(315deg,#007AAA, #007AAA 8px, white 8px, white 16px)",
                                                        opacity: 0.4,
                                                        height: "100%",
                                                    }}
                                                />
                                            </>
                                        }
                                    />
                                </div>

                                <div
                                    style={{
                                        display: "grid",
                                        width: "100%",
                                        gridTemplateColumns:
                                            "repeat(auto-fill, minmax(150px, 1fr))",
                                    }}
                                >
                                    {this.state.images.map((item, index) => {
                                        return (
                                            <div>
                                                <SingleImageUpload
                                                    style={{
                                                        flexGrow: 1,
                                                        width: "auto",
                                                    }}
                                                    image={item.image}
                                                    imgHolder={item.image || null}
                                                    index={index}
                                                    id={item.id}
                                                    onChangeOrder={(currentIndex, newIndex) =>
                                                        this.changeImageOrder(
                                                            currentIndex - 1,
                                                            newIndex - 1
                                                        )
                                                    }
                                                    onUpload={(data, i, id) =>
                                                        this.onUpload(data, i - 1, id)
                                                    }
                                                    onDelete={this.deleteImageItem.bind(this)}
                                                    disabledDeleteBtn={
                                                        this.state.images.length === 1
                                                    }
                                                    errorStatus={this.state.imagesState}
                                                />
                                            </div>
                                        );
                                    })}
                                    {this.state.image !== null &&
                                        this.state.image !== "" &&
                                        this.state.image !== undefined && (
                                            <div>
                                                <SingleImageUpload
                                                    style={{
                                                        margin: 2,
                                                        flexGrow: 1,
                                                        width: "100%",
                                                        height: "auto",
                                                        aspectRatio: 3 / 2,
                                                    }}
                                                    onUpload={(data, i, id) =>
                                                        this.onUpload(
                                                            data,
                                                            this.state.images.length,
                                                            id
                                                        )
                                                    }
                                                />
                                            </div>
                                        )}
                                </div>
                            </div>
                        </FlexContainer>
                    </FlexContainer>
                    <h3 style={{ color: "#DE2C48", fontWeight: 500 }}>Upplýsingar um tilboð</h3>
                    <FlexContainer column style={{ flexGrow: 1, gap: 24 }}>
                        <FlexContainer
                            gap={12}
                            style={{
                                flexGrow: 1,
                                justifyContent: "end",
                                flexWrap: "wrap",
                            }}
                        >
                            <CustomInput
                                formStyle={{ flex: "1 1 200px", fontSize: "1.2rem" }}
                                success={this.state.titleState === "success"}
                                error={this.state.titleState === "error"}
                                labelText={t("offer_title")}
                                id="title"
                                formControlProps={{ fullWidth: true }}
                                inputProps={{
                                    value: this.state.title,
                                    onChange: (event) => this.change(event, "title", "length", 5),
                                }}
                            />
                            <FlexContainer
                                style={{
                                    flexGrow: 1,
                                    flexWrap: "wrap",
                                    justifyContent: "flex-end",
                                }}
                                gap={24}
                            >
                                <CustomInput
                                    formStyle={{ flex: "1 1 200px", fontSize: "1.2rem" }}
                                    labelText={t("offer_title_en")}
                                    id="title_en"
                                    formControlProps={{ fullWidth: true }}
                                    inputProps={{
                                        value: this.state.title_en,
                                        onChange: (event) => this.change(event, "title_en"),
                                    }}
                                />
                                <Button
                                    color="success"
                                    size="sm"
                                    style={{ alignSelf: "flex-end", margin: 0 }}
                                    onClick={() =>
                                        this.autoTranslate("title", "title_en", "is", "en")
                                    }
                                >
                                    {t("translate_is_en")}
                                </Button>
                            </FlexContainer>
                        </FlexContainer>

                        <FlexContainer
                            gap={12}
                            style={{
                                flexGrow: 1,
                                justifyContent: "end",
                                flexWrap: "wrap",
                            }}
                        >
                            <CustomInput
                                className={`
              ${this.state.descriptionState === "success" && classes.txtAreaSuccess} 
              ${this.state.descriptionState === "error" && classes.txtAreaError}
            `}
                                inputProps={{
                                    minRows: 4,
                                    maxRows: 6,
                                    multiline: true,
                                    value: this.state.description,
                                    onChange: (event) =>
                                        this.change(event, "description", "length", 10),
                                }}
                                id="description"
                                aria-label="description"
                                labelText={t("offer_desc_is")}
                                formStyle={{ flex: "1 1 200px" }}
                            />
                            <FlexContainer
                                style={{
                                    flexGrow: 1,
                                    flexWrap: "wrap",
                                    justifyContent: "flex-start",
                                }}
                                gap={24}
                            >
                                <CustomInput
                                    formStyle={{ flex: "1 1 200px" }}
                                    id="description_en"
                                    aria-label="description_en"
                                    labelText={t("offer_desc_en")}
                                    inputProps={{
                                        minRows: 4,
                                        maxRows: 6,
                                        multiline: true,
                                        value: this.state.description_en,
                                        onChange: (event) => this.change(event, "description_en"),
                                    }}
                                />
                                <Button
                                    color="success"
                                    size="sm"
                                    style={{ alignSelf: "flex-start", margin: 0 }}
                                    onClick={() =>
                                        this.autoTranslate(
                                            "description",
                                            "description_en",
                                            "is",
                                            "en"
                                        )
                                    }
                                >
                                    {t("translate_is_en")}
                                </Button>
                            </FlexContainer>
                        </FlexContainer>
                    </FlexContainer>
                    <FlexContainer
                        gap={24}
                        style={{ alignItems: "flex-end", flexWrap: "wrap", marginTop: 12 }}
                    >
                        {this.user_is_group_admin && companies && (
                            <FlexContainer style={{ flex: "1 1 200px" }}>
                                <Autocomplete
                                    sx={{
                                        "& .MuiOutlinedInput-root": {
                                            padding: "10px 14px", // Adjust padding to align content properly
                                        },
                                    }}
                                    variant="outlined"
                                    id={"companies"}
                                    onChange={(event, newValue) => {
                                        this.handleChangeCompanyGroup(newValue, "companyId");
                                    }}
                                    value={
                                        this.state.companyId
                                            ? optionsCompany?.find?.(
                                                  (item) => item.id === this.state.companyId
                                              )
                                            : null
                                    }
                                    options={
                                        optionsCompany
                                            ? optionsCompany.sort?.(
                                                  (a, b) =>
                                                      -b.firstLetter.localeCompare(a.firstLetter)
                                              )
                                            : []
                                    }
                                    groupBy={(option) => option.firstLetter}
                                    getOptionLabel={(option) => option?.name}
                                    getOptionSelected={(option, value) => option.id === value.id}
                                    style={{ marginTop: 10, flex: 1 }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            InputLabelProps={{
                                                shrink: true, // This forces the label to shrink
                                            }}
                                            sx={{
                                                "& .MuiInputBase-root": {
                                                    padding: "0", // Set padding of the root container (outer padding)
                                                },
                                                "& .MuiAutocomplete-input": {
                                                    marginTop: 0,
                                                    marginBottom: 0, // Adjust padding for the input area
                                                    paddingLeft: 10,
                                                },
                                                "& .MuiInputBase-input": {
                                                    padding: "10px 14px", // Customize internal input padding
                                                },
                                            }}
                                            label={t("companies")}
                                            error={this.state.companyState === "error"}
                                        />
                                    )}
                                />
                            </FlexContainer>
                        )}
                        {!this.user_is_group_admin && this.state.companies.length > 1 && (
                            <FlexContainer style={{ flex: "1 1 200px" }}>
                                <FormControl fullWidth>
                                    <InputLabel htmlFor="simple-select">
                                        {t("companies")}
                                    </InputLabel>
                                    <Select
                                        sx={{
                                            "& .MuiOutlinedInput-input": {
                                                padding: "10px 14px", // Adjust padding to align content properly
                                            },
                                        }}
                                        value={this.state.companyId}
                                        onChange={(e) =>
                                            this.handleChangeCompany(e.target.value, "companyId")
                                        }
                                        inputProps={{ name: "companyId", id: "companyId" }}
                                    >
                                        {this.state.companies
                                            .sort((a, b) =>
                                                a.name > b.name ? 1 : b.name > a.name ? -1 : 0
                                            )
                                            .map((value, index) => (
                                                <MenuItem key={index} value={value.id}>
                                                    {value.name}
                                                </MenuItem>
                                            ))}
                                    </Select>
                                </FormControl>
                            </FlexContainer>
                        )}
                        {this.store.roles.length === 1 &&
                            this.store.roles[0] === "group_admin" &&
                            this.store.groupIds.length > 1 &&
                            data.results && (
                                <FlexContainer style={{ flex: "1 1 200px" }}>
                                    <FormControl
                                        fullWidth
                                        error={this.state.groupState === "error"}
                                    >
                                        <InputLabel
                                            htmlFor="simple-select"
                                            shrink={true}
                                            style={{ backgroundColor: "white" }}
                                        >
                                            {t("special_companies")}
                                        </InputLabel>
                                        <Select
                                            sx={{
                                                "& .MuiOutlinedInput-input": {
                                                    padding: "7.5px 14px", // Adjust padding to align content properly
                                                },
                                            }}
                                            value={this.state.torgid_special_company_ids}
                                            onChange={(e) => this.handleChangeOption(e)}
                                            inputProps={{
                                                name: "special_company",
                                                id: "roles-select",
                                            }}
                                            multiple
                                            renderValue={(selected) =>
                                                selected.map((value, index) => {
                                                    let text =
                                                        value === 0
                                                            ? t("offer.special_companies_Default")
                                                            : data.results.find(
                                                                  (x) => x.id === value
                                                              )?.name || "";
                                                    if (index < selected.length - 1) {
                                                        text += ", ";
                                                    }
                                                    return text;
                                                })
                                            }
                                        >
                                            {data.results &&
                                                data.results
                                                    .filter(
                                                        (item) =>
                                                            item.id !== 1 &&
                                                            item.id !== 3 &&
                                                            this.store.groupIds.includes(item.id)
                                                    )
                                                    .map((value, index) => (
                                                        <MenuItem key={value.id} value={value.id}>
                                                            <Checkbox
                                                                checked={
                                                                    this.state.torgid_special_company_ids.indexOf(
                                                                        value.id
                                                                    ) > -1
                                                                }
                                                            />
                                                            {value.name}
                                                        </MenuItem>
                                                    ))}
                                        </Select>
                                    </FormControl>
                                </FlexContainer>
                            )}
                        {this.store.roles.includes("company_user") && data.results && (
                            <FlexContainer style={{ flex: "1 1 200px" }}>
                                <FormControl
                                    fullWidth
                                    className={classes.selectFormControl}
                                    style={{ marginTop: "10px" }}
                                    error={this.state.groupState === "error"}
                                >
                                    <InputLabel
                                        htmlFor="simple-select"
                                        className={classes.selectLabel}
                                        shrink={true}
                                    >
                                        {t("special_companies")}
                                    </InputLabel>
                                    <Select
                                        sx={{
                                            "& .MuiOutlinedInput-input": {
                                                padding: "10px 14px", // Adjust padding to align content properly
                                            },
                                        }}
                                        MenuProps={{
                                            className: classes.selectMenu,
                                            style: { zIndex: 35001 },
                                        }}
                                        style={{ padding: "0px 10px 5px 10px" }}
                                        classes={{ select: classes.select }}
                                        value={this.state.torgid_special_company_ids}
                                        onChange={(e) => this.handleChangeOption(e)}
                                        inputProps={{ name: "special_company", id: "roles-select" }}
                                        multiple
                                        renderValue={(selected) =>
                                            selected.map((value, index) => {
                                                return value === 0
                                                    ? t("offer.special_companies_Default")
                                                    : (data.results.find((x) => x.id === value)
                                                          ?.name || "") + ", ";
                                            })
                                        }
                                    >
                                        <MenuItem
                                            key={"torgid_special_company_id_null"}
                                            value={0}
                                            classes={{
                                                root: classes.selectMenuItem,
                                                selected: classes.selectMenuItemSelected,
                                            }}
                                        >
                                            <RadioGroup
                                                row
                                                aria-labelledby="default_opt"
                                                value={
                                                    this.state.torgid_special_company_ids.indexOf(
                                                        0
                                                    ) > -1
                                                        ? 0
                                                        : null
                                                }
                                                name="row-group_0"
                                                className={classes.special_option}
                                            >
                                                <FormControlLabel
                                                    className={classes.special_option_label}
                                                    value={0}
                                                    control={<Radio />}
                                                    label={t("offer.special_companies_Default")}
                                                />
                                            </RadioGroup>
                                        </MenuItem>
                                        <MenuItem
                                            key={"torgid_special_company_id_lyk"}
                                            value={1}
                                            classes={{
                                                root: classes.selectMenuItem,
                                                selected: classes.selectMenuItemSelected,
                                            }}
                                        >
                                            <RadioGroup
                                                row
                                                aria-labelledby="default_opt"
                                                value={
                                                    this.state.torgid_special_company_ids.indexOf(
                                                        1
                                                    ) > -1
                                                        ? 1
                                                        : null
                                                }
                                                name="row-group_1"
                                                className={classes.special_option}
                                            >
                                                <FormControlLabel
                                                    className={classes.special_option_label}
                                                    value={1}
                                                    control={<Radio />}
                                                    label={t("offer.special_companies_Lykilkjör")}
                                                />
                                            </RadioGroup>
                                        </MenuItem>
                                        <MenuItem
                                            key={"torgid_special_company_id_b2b"}
                                            value={3}
                                            classes={{
                                                root: classes.selectMenuItem,
                                                selected: classes.selectMenuItemSelected,
                                            }}
                                        >
                                            <RadioGroup
                                                row
                                                aria-labelledby="default_opt"
                                                value={
                                                    this.state.torgid_special_company_ids.indexOf(
                                                        3
                                                    ) > -1
                                                        ? 3
                                                        : null
                                                }
                                                name="row-group_3"
                                                className={classes.special_option}
                                            >
                                                <FormControlLabel
                                                    className={classes.special_option_label}
                                                    value={3}
                                                    control={<Radio />}
                                                    label={t("offer.special_companies_B2B")}
                                                />
                                            </RadioGroup>
                                        </MenuItem>

                                        {data.results &&
                                            data.results
                                                .filter(
                                                    (item) =>
                                                        item.id !== 1 &&
                                                        item.id !== 3 &&
                                                        item.hidden !== true
                                                )
                                                .map((value, index) => (
                                                    <MenuItem key={value.id} value={value.id}>
                                                        <Checkbox
                                                            checked={
                                                                this.state.torgid_special_company_ids.indexOf(
                                                                    value.id
                                                                ) > -1
                                                            }
                                                        />
                                                        {value.name}
                                                    </MenuItem>
                                                ))}
                                    </Select>
                                </FormControl>
                            </FlexContainer>
                        )}
                    </FlexContainer>
                    <FlexContainer gap={24} style={{ flexWrap: "wrap" }}>
                        <FlexContainer column style={{ flex: 1, minWidth: 240, gap: 12 }}>
                            <FlexContainer style={{ alignItems: "center", marginTop: 20 }}>
                                <h3
                                    style={{
                                        color: "#DE2C48",
                                        fontWeight: 500,
                                        marginRight: 8,
                                        marginTop: 0,
                                    }}
                                >
                                    Tegund tilboðs
                                </h3>
                            </FlexContainer>
                            <FormControl fullWidth>
                                <InputLabel
                                    htmlFor="simple-select"
                                    shrink={true}
                                    style={{ background: "white" }}
                                >
                                    {t("offer_new_discount")}
                                </InputLabel>
                                <Select
                                    sx={{
                                        "& .MuiOutlinedInput-input": {
                                            padding: "10px 14px", // Adjust padding to align content properly
                                        },
                                    }}
                                    value={this.state.discount}
                                    onChange={(e) => this.handleChange(e.target.value, "discount")}
                                    inputProps={{ name: "discount", id: "discount" }}
                                    style={{ marginBottom: 0 }}
                                    label={t("offer_new_discount")}
                                >
                                    <MenuItem
                                        classes={{ selected: classes.dSelected }}
                                        value="discount"
                                    >
                                        {t("offer_discount")}
                                    </MenuItem>
                                    <MenuItem
                                        classes={{ selected: classes.dSelected }}
                                        value="price"
                                    >
                                        {t("offer_price")}
                                    </MenuItem>
                                    <MenuItem
                                        classes={{ selected: classes.dSelected }}
                                        value="2 fyrir 1"
                                    >
                                        {t("offer_2_for_1")}
                                    </MenuItem>
                                    <MenuItem
                                        classes={{ selected: classes.dSelected }}
                                        value="sérkjör"
                                    >
                                        {t("offer_special")}
                                    </MenuItem>
                                    <MenuItem
                                        classes={{ selected: classes.dSelected }}
                                        value="tilkynning"
                                    >
                                        {t("offer_announcement")}
                                    </MenuItem>
                                </Select>
                            </FormControl>
                            {(this.state.discount === "discount" ||
                                this.state.discount === "price") && (
                                <CustomInput
                                    labelText={<span> {t("offer_displayed_discount")} </span>}
                                    id="discount_input"
                                    formControlProps={{ fullWidth: true }}
                                    classes={{ select: classes.select }}
                                    inputProps={{
                                        value: this.state.discountInput,
                                        onChange: (event) => this.change(event, "discountInput"),
                                        endAdornment: (
                                            <InputAdornment
                                                position="end"
                                                className={classes.inputAdornment}
                                            >
                                                {this.state.discount === "discount" ? "%" : "kr."}
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            )}
                            <FlexContainer style={{ alignItems: "center", marginTop: 36 }}>
                                <h3
                                    style={{
                                        color: "#DE2C48",
                                        fontWeight: 500,
                                        marginRight: 8,
                                        marginTop: 0,
                                    }}
                                >
                                    Virkjun tilboðs
                                </h3>

                                <HoverInfoBox>Veldu hvernig notendur virkja tilboðið.</HoverInfoBox>
                            </FlexContainer>
                            <FlexContainer style={{ alignItems: "center", gap: 12 }}>
                                <FormControl fullWidth>
                                    <InputLabel
                                        htmlFor="simple-select"
                                        shrink={true}
                                        style={{ background: "white" }}
                                    >
                                        {t("activate_offer")}
                                    </InputLabel>
                                    <Select
                                        sx={{
                                            "& .MuiOutlinedInput-input": {
                                                padding: "10px 14px", // Adjust padding to align content properly
                                            },
                                        }}
                                        style={{ margin: 0 }}
                                        label={t("activate_offer")}
                                        value={this.state.action_type}
                                        onChange={(e) =>
                                            this.handleChange(e.target.value, "action_type")
                                        }
                                        inputProps={{ name: "action_type", id: "action_type" }}
                                    >
                                        <MenuItem
                                            classes={{ selected: classes.dSelected }}
                                            value="timer"
                                        >
                                            {t("offer_timer")}
                                        </MenuItem>
                                        <MenuItem
                                            classes={{ selected: classes.dSelected }}
                                            value="promocode"
                                        >
                                            {t("offer_promocode")}
                                        </MenuItem>
                                        <MenuItem
                                            classes={{ selected: classes.dSelected }}
                                            value="email"
                                        >
                                            {t("offer_email")}
                                        </MenuItem>
                                        <MenuItem
                                            classes={{ selected: classes.dSelected }}
                                            value="phone"
                                        >
                                            {t("offer_phone")}
                                        </MenuItem>
                                        <MenuItem
                                            classes={{ selected: classes.dSelected }}
                                            value="website"
                                        >
                                            {t("offer_website")}
                                        </MenuItem>
                                        <MenuItem
                                            classes={{ selected: classes.dSelected }}
                                            value="barcode"
                                        >
                                            {t("offer_barcode")}
                                        </MenuItem>
                                        <MenuItem
                                            classes={{ selected: classes.dSelected }}
                                            value="qrcode"
                                        >
                                            {t("offer_qr_code")}
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                                {activationHelpText && (
                                    <HoverInfoBox>{activationHelpText}</HoverInfoBox>
                                )}
                            </FlexContainer>

                            {(this.state.action_value || this.state.action_type) &&
                                this.state.action_type !== "timer" && (
                                    <CustomInput
                                        labelText={
                                            <span>
                                                {activateOfferType.hasOwnProperty(
                                                    this.state.action_type
                                                )
                                                    ? t("offer_active_" + this.state.action_type)
                                                    : "Value"}
                                            </span>
                                        }
                                        id="action_value"
                                        formControlProps={{ fullWidth: true }}
                                        inputProps={{
                                            value: this.state.action_value,
                                            onChange: (event) => this.change(event, "action_value"),
                                        }}
                                    />
                                )}
                            {this.state.action_type === "promocode" && (
                                <CustomInput
                                    labelText={<span> {t("offer_link")} </span>}
                                    id="offer_link"
                                    formControlProps={{ fullWidth: true }}
                                    inputProps={{
                                        value: this.state.offer_link,
                                        onChange: (event) => this.change(event, "offer_link"),
                                    }}
                                />
                            )}
                        </FlexContainer>
                        <FlexContainer column style={{ flex: 1 }}>
                            <FlexContainer style={{ alignItems: "center", marginTop: 20 }}>
                                <h3
                                    style={{
                                        color: "#DE2C48",
                                        fontWeight: 500,
                                        marginRight: 8,
                                        marginTop: 0,
                                    }}
                                >
                                    Hvenær er tilboðið virkt?
                                </h3>
                            </FlexContainer>
                            <FlexContainer style={{ gap: 12 }}>
                                <FormControl>
                                    <Datetime
                                        value={this.state.start_date}
                                        onChange={(e) => this.handleChange(e, "start_date")}
                                        timeFormat={false}
                                        closeOnSelect={true}
                                        className={classes.datePicker}
                                        inputProps={{ placeholder: t("offer_date_from") }}
                                        dateFormat="ddd, LL"
                                    />
                                </FormControl>

                                <FormControl>
                                    <Datetime
                                        value={this.state.end_date}
                                        onChange={(e) => this.handleChange(e, "end_date")}
                                        timeFormat={false}
                                        closeOnSelect={true}
                                        className={classes.datePicker}
                                        inputProps={{ placeholder: t("offer_date_till") }}
                                        dateFormat="ddd, LL"
                                    />
                                </FormControl>
                            </FlexContainer>
                            <FlexContainer style={{ marginTop: 10 }}>
                                <Button
                                    size="sm"
                                    onClick={() => this.handleSelectAllActiveTimes()}
                                    style={{ margin: 0, flexShrink: 1, marginBottom: 10 }}
                                    color={alwaysActive ? "success" : "default"}
                                >
                                    Alltaf
                                </Button>
                            </FlexContainer>
                            <FlexContainer>
                                <table
                                    style={{ margin: 0, padding: 0, borderCollapse: "collapse" }}
                                >
                                    <tbody>{renderActiveTime}</tbody>
                                </table>
                            </FlexContainer>
                        </FlexContainer>
                    </FlexContainer>
                </FlexContainer>
            </>
        );
    }
}

export const HourInput = ({ value, onChange, ...props }) => {
    return (
        <input
            id="name"
            style={{
                padding: "0.375rem 0.1rem",
                width: 65,
                textAlign: "center",
                border: "1px solid #ced4da",
                borderRadius: 8,
            }}
            className="form-control"
            value={value}
            onChange={(event) => {
                onChange?.(event.target.value);
            }}
            onFocus={(event) => {
                event.target.select();
            }}
            onBlur={(event) => {
                onChange?.(getTime(event.target.value));
            }}
            {...props}
        />
    );
};

const getTime = (textValue) => {
    const value = parseInt(textValue);
    if (isNaN(value)) {
        return textValue;
    }

    let newValue = "";

    if (value >= 0 && value <= 24 && !textValue.includes(":")) {
        newValue = (value < 10 ? "0" : "") + value.toString() + ":00";
    }

    if (textValue.length == 3) {
        newValue = "0" + textValue.substring(0, 1) + ":" + textValue.substring(1, 3);
    }

    if (textValue.length == 4) {
        newValue = textValue.substring(0, 2) + ":" + textValue.substring(2, 4);
    }
    if (newValue == "") {
        return textValue;
    }
    newValue = newValue.substring(0, 5);
    return newValue;
};

export default withTranslation("", { withRef: true })(Step1);
