import React, { useState } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import i18n from "../../locales/i18n";

import { useDispatch } from "react-redux";
import { logout } from "../../store/actions/auth";
import { useNavigate } from "react-router-dom";
import { createUseStyles } from "react-jss";

// @mui/material components
import { MenuItem, MenuList, Paper, Grow, Hidden, Popper } from "@mui/material";
import { ClickAwayListener } from "@mui/base";
import { Language, ExitToApp } from "@mui/icons-material";

// core components
import Button from "../CustomButtons/Button.js";

import styles from "../../assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
import IS from "../../assets/img/flags/IS.png";
import EN from "../../assets/img/flags/US.png";

const useStyles = createUseStyles(styles);

function HeaderLinks(props) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [openLang, setOpenLeng] = useState(null);
    const classes = useStyles();
    const { rtlActive } = props;
    const lang =
        i18n.language === "en-US" || i18n.language === "en-GB"
            ? "EN"
            : i18n.language === "ru-RU"
            ? "RU"
            : i18n.language === "is-IS"
            ? "IS"
            : i18n.language;

    const handleClickLang = (event) => {
        if (openLang && openLang.contains(event.target)) {
            setOpenLeng(null);
        } else {
            setOpenLeng(event.currentTarget);
        }
    };

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        setOpenLeng(null);
        localStorage.setItem("lang", lng);
    };

    const handleCloseLang = () => {
        setOpenLeng(null);
    };

    const handleClickProfile = (event) => {
        dispatch(logout(navigate));
    };

    const dropdownItem = classNames(classes.dropdownItem, classes.primaryHover, {
        [classes.dropdownItemRTL]: rtlActive,
    });

    const wrapper = classNames({
        [classes.wrapperRTL]: rtlActive,
    });

    const managerClasses = classNames({
        [classes.managerClasses]: true,
    });

    return (
        <div className={wrapper}>
            <div className={managerClasses}>
                <Button
                    color="transparent"
                    justIcon
                    aria-label="lang"
                    aria-owns={openLang ? "notification-menu-list" : null}
                    aria-haspopup="true"
                    onClick={handleClickLang}
                    className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
                    muiClasses={{ label: rtlActive ? classes.labelRTL : "" }}
                    title={"Tungumál"}
                >
                    <Language
                        className={
                            classes.headerLinksSvg +
                            " " +
                            (rtlActive ? classes.links + " " + classes.linksRTL : classes.links)
                        }
                    />
                    <Hidden smUp implementation="css">
                        <span className={classes.notifications}>{lang}</span>
                    </Hidden>
                </Button>
                <Popper
                    open={Boolean(openLang)}
                    anchorEl={openLang}
                    transition
                    disablePortal
                    placement="bottom"
                    className={classNames({
                        [classes.popperClose]: !openLang,
                        [classes.popperResponsive]: true,
                        [classes.popperNav]: true,
                    })}
                >
                    {({ TransitionProps }) => (
                        <Grow
                            {...TransitionProps}
                            id="notification-menu-list"
                            style={{ transformOrigin: "0 0 0" }}
                        >
                            <Paper className={classes.dropdown}>
                                <ClickAwayListener onClickAway={handleCloseLang}>
                                    <MenuList role="menu">
                                        <MenuItem
                                            onClick={() => changeLanguage("is")}
                                            className={dropdownItem}
                                        >
                                            <img src={IS} alt="IS" /> {`  `}
                                            Íslenska
                                        </MenuItem>
                                        <MenuItem
                                            onClick={() => changeLanguage("en")}
                                            className={dropdownItem}
                                        >
                                            <img src={EN} alt="EN" /> {`  `}
                                            English
                                        </MenuItem>
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </div>

            <div className={managerClasses}>
                <Button
                    color="transparent"
                    aria-label="Person"
                    justIcon
                    aria-owns={null}
                    aria-haspopup="true"
                    onClick={handleClickProfile}
                    title={"Útskráning"}
                    className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
                    muiClasses={{
                        label: rtlActive ? classes.labelRTL : "",
                    }}
                >
                    <ExitToApp
                        className={
                            classes.headerLinksSvg +
                            " " +
                            (rtlActive ? classes.links + " " + classes.linksRTL : classes.links)
                        }
                    />
                    <Hidden smUp implementation="css">
                        <span onClick={handleClickProfile} className={classes.linkText}>
                            Log out
                        </span>
                    </Hidden>
                </Button>
            </div>
        </div>
    );
}

HeaderLinks.propTypes = {
    rtlActive: PropTypes.bool,
};

export default HeaderLinks;
