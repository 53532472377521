/*eslint-disable*/
import React, { useEffect } from "react";
import Danger from "components/Typography/Danger.js";
import Success from "components/Typography/Success.js";

import imgHolder from "assets/img/default-avatar.png";
import defaultImage from "assets/img/placeholder.jpg";
import SweetAlert from "react-bootstrap-sweetalert";

export default function PictureUpload(props) {
    const [file, setFile] = React.useState(null);
    const [alert, setAlert] = React.useState(null);
    const [msg, setMsg] = React.useState(props.msg || "Choose Picture");
    const [titleMsg, setTitleMsg] = React.useState(props.titleMsg || "");
    const [disable] = React.useState(props.disabled || false);
    const [imagePreviewUrl, setImagePreviewUrl] = React.useState(props.imgHolder || defaultImage);

    useEffect(() => {
        props.imgHolder &&
            props.imgHolder !== imagePreviewUrl &&
            setImagePreviewUrl(props.imgHolder);
    }, [props.imgHolder]);

    const hideAlert = () => {
        setAlert(null);
    };

    const handleImageChange = (e) => {
        e.preventDefault();
        let reader = new FileReader();
        if (e.target.value.length == 0) {
            setImagePreviewUrl(defaultImage);
            setMsg(<Danger>Error: Image is not selected</Danger>);
            return;
        }
        let newFile = e.target.files[0];
        var fileTypes = ["jpg", "jpeg", "png", "svg"]; //acceptable file types
        var extension = newFile && newFile.name.split(".").pop().toLowerCase(), //file extension from input file
            isSuccess = fileTypes.indexOf(extension) > -1; //is extension in acceptable types

        if (newFile && newFile.size >= 800000) {
            // setMsg(<Danger>Error: Image size is large</Danger>);
            setAlert(
                <SweetAlert
                    error
                    title="Error: Image size is large, Max 800KB"
                    showConfirm={false}
                    onConfirm={() => hideAlert()}
                    onCancel={() => hideAlert()}
                    timeout={2000}
                    closeOnClickOutside={true}
                />
            );
            return;
        }

        if (!isSuccess) {
            // setMsg(<Danger>Error: Image type is not supported</Danger>);
            setAlert(
                <SweetAlert
                    error
                    title="Error: Image type is not supported"
                    showConfirm={false}
                    onConfirm={() => hideAlert()}
                    onCancel={() => hideAlert()}
                    timeout={2000}
                    closeOnClickOutside={true}
                />
            );
            return;
        }

        reader.onloadend = () => {
            setFile(newFile);
            setImagePreviewUrl(reader.result);
            setMsg(<Success>Success</Success>);
            props.onUpload(reader.result);
        };
        if (newFile) {
            reader.readAsDataURL(newFile);
        }
    };
    // eslint-disable-next-line
    const handleSubmit = (e) => {
        e.preventDefault();
        // this.state.file is the file/image uploaded
        // in this function you can save the image (this.state.file) on form submit
        // you have to call it yourself
    };
    return (
        <div className="picture-container">
            {alert}
            {titleMsg && <h6 className="titleMsg">{titleMsg}</h6>}
            <div className="picture">
                <div
                    style={{ backgroundImage: "url(" + imagePreviewUrl + ")" }}
                    className="picture-src"
                    alt="..."
                />
                <input type="file" onChange={(e) => handleImageChange(e)} disabled={disable} />
            </div>
            <h6 className="description">{msg}</h6>
        </div>
    );
}
